import styled from 'styled-components';

export const StyledEnrolmentPolicyContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  padding: 16px;
  min-width: 415px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${(p) => p.theme.colors.white};
`;
export const StyledPolicyInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const StyledInsurerLogo = styled.img`
  width: 48px;
  height: 48px;
  padding: 4px;
  border-radius: 12px;
  border: 1px solid ${(p) => p.theme.colors.consultViolet};
`;
export const StyledPolicyDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  width: 190px;
`;
