import { DateFormatUtils } from '@loophealth/loop-ui-web-library';

const isDummyRow = (member:Record<string, unknown>) => {
    return (member?.['Employee ID'] ?? member?.['User ID']) === 'Any string allowed' &&
        member?.['Relationship'] === 'Select from dropdown' &&
        member?.['Date of Leaving'] === 'DD/MM/YYYY';
};
const transformMemberToBe = (member: Record<string, unknown>) => {
    const dateFormatUtils = new DateFormatUtils();
    return {
        userId: member?.['Employee ID'] ?? member?.['User ID'],
        relationship: member?.['Relationship'],
        dateOfLeaving: dateFormatUtils.excelToBackendDateFormat(member?.['Date of Leaving'] as string),
        policyException: member?.['Exception']
    };
};

export const transformOffboardMembersForUI = ({
    userId='',
    relationship='',
    dateOfLeaving='',
    exception='',
    employeeName='',
    policyCount='',
    memberName='',
    error='',
    ...rest
}: Record<string, unknown>): Record<string, unknown> => ({
    'User ID': userId,
    'Relationship': relationship,
    'Date of Leaving': dateOfLeaving,
    'Exception': exception,
    'Member Name': memberName,
    'Employee Name': employeeName,
    'Total Policy Count': policyCount,
    'Error messages': error,
    ...rest
});

export const getMemberListFromJson = (json: Record<string, unknown>[]):Record<string, unknown>[] => {
    if (isDummyRow(json[0])) {
        return json.slice(1).map(transformMemberToBe);
    }
    return json.map(transformMemberToBe);
};
