import React from 'react';
import { Button, PageHeader, Loader } from '../../../../atoms';
import { StyledInputContainer } from '../GlobalMemberSearch/styles';
import { StyledContainer, StyledWrapper } from './styles';
import { addHrRole, downloadHRMappings } from '../../../../../adapters';
import { useToast } from '../../../../hooks/useToast';
import { LoopButton } from '@loophealth/loop-ui-web-library';
import { excelDownloadUsingBlob } from '../../../../../utils/common';
// import { isSearchMemberByEmpIdFeatureEnabled } from '../../../../../utils/featureFlags';

const HrAddition: React.FunctionComponent = () => {
    const toast = useToast();
    const [isPersistanceLoading, setIsPersistanceLoading] = React.useState(false);

    const onHrAddition = async () => {
        setIsPersistanceLoading(true);
        const result = await addHrRole();
        if (result.data.hrEmailOrMobileExist.length > 0) {
            const HRDetails = result.data.hrEmailOrMobileExist;
            toast?.displayToast('error', 'HR mobile or email already exists', HRDetails.join(','));
            setIsPersistanceLoading(false);
        }
        if (result.data.missingHrOrCompanyDetails.length > 0) {
            const missingHrOrCompanyDetails = result.data.missingHrOrCompanyDetails;
            toast?.displayToast('error', 'Missing company details', missingHrOrCompanyDetails.join(','));
            setIsPersistanceLoading(false);
        }
        if (
            result.data.successfullyAddedHr.length == 0 &&
            result.data.missingHrOrCompanyDetails.length == 0 &&
            result.data.hrEmailOrMobileExist.length == 0
        ) {
            toast?.displayToast('error', 'Please check excel sheet', '');
            setIsPersistanceLoading(false);
        }

        if (result.data.successfullyAddedHr.length > 0) {
            toast?.displayToast('success', 'Policy preValidation added successfully', '');
            setIsPersistanceLoading(false);
        }
    };
    const url = process.env.REACT_APP_GS_PREVALIDATION_AND_HRD_SETUP_HRD_ACCESS_MANAGEMENT;

    const onHrMappings = async () => {
        const getRsp = await downloadHRMappings();
        excelDownloadUsingBlob(getRsp);
    };
    return (
        <StyledContainer>
            <div>
                <PageHeader>Add Hr Details For HRD access </PageHeader>
            </div>
            <StyledInputContainer>
                {isPersistanceLoading ? (
                    <Loader />
                ) : (
                    <Button type="primary" onClick={onHrAddition}>
                        Add Hr Details
                    </Button>
                )}
            </StyledInputContainer>
            <div>
                (<b>NOTE:</b>Above button will invoke Hr addition script which will read data from{' '}
                <a href={url} target="_blank" rel="noreferrer">
                    excel
                </a>{' '}
                and store it in firebase database)
            </div>
            <StyledWrapper>
                <LoopButton variant="filled" onClick={onHrMappings}>
                    Download HR Mappings
                </LoopButton>
            </StyledWrapper>
        </StyledContainer>
    );
};

export default HrAddition;
