import React, { useRef, useState } from 'react';
import { IFormInput } from './types';

const useFormInput = (initalValue: string, mandatory = true, name?: string): IFormInput => {
    const [value, setValue] = useState<string>(initalValue);
    const ref = useRef<HTMLInputElement | null>(null);
    const [debounceValue, setDebounceValue] = useState<string>(value);
    const [typingTimeout, setTypingTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
    const [errorText, setErrorText] = useState<string>('');

    const finalValidationCheck = (): boolean => {
        let validity = true;
        if (!value.length) {
            setErrorText('This field cannot be empty');
            validity = false;
        }
        const error = formValidator(
            ref.current?.getAttribute('name') || name || 'text',
            ref.current?.getAttribute('value') || value || '',
            mandatory
        );
        if (error) {
            validity = false;
            setErrorText(error);
        } else {
            setErrorText('');
        }
        return validity;
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formValue = e.currentTarget.value;
        const formName = e.currentTarget.name;
        if (typingTimeout) {
            clearTimeout(typingTimeout);
            setErrorText('');
        }
        setTypingTimeout(
            setTimeout(() => {
                setErrorText(formValidator(formName, formValue, mandatory));
                setDebounceValue(formValue);
            }, 350)
        );
        setValue(formValue);
    };
    return {
        value,
        debounceValue,
        errorText,
        setErrorText,
        setValue,
        onChange,
        finalValidationCheck,
        ref
    };
};

const formValidator = (name: string, value: string, mandatory: boolean): string => {
    let errorValue = '';
    switch (name) {
    case 'name': {
        if (!value.trim().length && mandatory) {
            errorValue = 'Please enter a valid name';
        }
        break;
    }
    case 'text': {
        if (!value.trim().length && mandatory) {
            errorValue = 'Please enter a valid value';
        }
        break;
    }
    case 'address': {
        if (!value.trim().length && mandatory) {
            errorValue = 'Please enter a valid address';
        }
        break;
    }
    case 'email': {
        if (!regexValidations.email.test(value.toLowerCase()) && value.length) {
            errorValue = 'Please enter a valid email';
        }
        break;
    }
    case 'phone': {
        if (!regexValidations.phone.test(value.toLowerCase()) && value.length) {
            errorValue = 'Please enter a valid phone number';
        }
        break;
    }
    case 'password': {
        if (mandatory && !value.trim().length) {
            errorValue = 'Please enter a valid password';
        } else if ((value.length < 6 || value.length > 32) && value.length) {
            errorValue = 'Password should be at least from 6 to 32 characters';
        } else if (!regexValidations.password.test(value) && value.length) {
            errorValue = `Password should have to include 
                alpha-numberic and special characters`;
        }
        break;
    }
    case 'gstin': {
        if (mandatory && !value.trim().length) {
            errorValue = 'GST number cannot be empty';
        } else if (!regexValidations.gstin.test(value.toUpperCase()) && value.length) {
            errorValue = 'Please enter a valid GST number';
        } else if (value.trim().length !== 15) {
            errorValue = 'GST number must be of 15 digits only!';
        }
        break;
    }
    case 'pan': {
        if (mandatory && !value.trim().length) {
            errorValue = 'PAN number cannot be empty';
        } else if (!regexValidations.pan.test(value.toUpperCase()) && value.length) {
            errorValue = 'Please enter a valid PAN number';
        } else if (value.length !== 10) {
            errorValue = 'PAN number must be of 10 digits only!';
        }
        break;
    }
    case 'salesforceId': {
        if (mandatory && !value.trim().length) {
            errorValue = 'Please enter Salesforce ID';
        } else if (!regexValidations.salesforceId.test(value)) {
            errorValue = 'Salesforce ID must follow A-XXXXX format (X for numbers)';
        }
        break;
    }
    case 'nickname': {
        if (mandatory && !value.trim().length) {
            errorValue = 'Please enter company nickname';
        } else if (value.length > 30) {
            errorValue = 'Company nickname should be less than 30 characters';
        }
        break;
    }
    default:
        break;
    }

    return errorValue;
};

const regexValidations = {
    email: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+$/,
    phone: /^[789]\d{9}$/,
    password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-]).{6,32}$/,
    gstin: /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
    pan: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
    salesforceId: /^A-\d{5}$/
};

export default useFormInput;
