import { IPlacement } from './PlacementDetails';

export type IRadioButtonOptions = {
    value: string;
    id: string;
};

export const radioButtonOptions: IRadioButtonOptions[] = [
    {
        value: 'Yes',
        id: 'true'
    },
    {
        value: 'No',
        id: 'false'
    }
];

export const policyTypeDetails: IPlacement[] = [
    {
        isMandatory: true,
        label: 'Is this policy a Top-Up Policy?',
        name: 'isTopUpPolicy',
        type: 'radioButton',
        fullWidth: false,
        inputType: 'boolean',
        display: true
    },
    {
        isMandatory: true,
        label: 'Is this policy a Parental Policy?',
        name: 'isParentalPolicy',
        type: 'radioButton',
        fullWidth: false,
        inputType: 'boolean',
        display: true
    },
    {
        isMandatory: true,
        label: 'Please select a Base Policy for the Top-Up',
        name: 'basePolicy',
        type: 'radioButton',
        fullWidth: false,
        inputType: 'boolean',
        display: true
    }
];
