import React, { useState } from 'react';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { StyledContainer } from './styles';
import CompanyPoliciesComponent from './CompanyPoliciesComponent';
import DateCycleSelectionComponent from './DateCycleSelectionComponent';
import { StyledButtonWrapper } from '../../../../containers/RenameEndoModal/styles';
import { ICompanyData } from '../../../../../redux/slices/CompanySlice/types';
import { IPolicyPlan } from '../../../../../redux/slices/PolicySlice/types';

const EnrolmentConfig: React.FunctionComponent = () => {
    const [selectedCompany, setSelectedCompany] = useState<ICompanyData | null>(null);
    const [selectedPolicies, setSelectedPolicies] = useState<IPolicyPlan[]>([]);
    const [memberRegistrationStartDate, setMemberRegistrationStartDate] = useState<Date >(new Date());
    const [memberRegistrationEndDate, setMemberRegistrationEndDate] = useState<Date >(new Date());
    const [endoCycleStartDate, setEndoCycleStartDate] = useState<Date >(new Date());
    const [endoCycleEndDate, setEndoCycleEndDate] = useState<Date >(new Date());

    const isShowPreviewEnabled = selectedCompany && selectedPolicies?.length && memberRegistrationStartDate &&
        memberRegistrationEndDate && endoCycleStartDate && endoCycleEndDate;

    const onShowPreviewClicked = () => {
        console.log('===onShowPreviewClicked');
    };
    return (
        <StyledContainer>
            <Typography variant='title2' weight='medium'>Set up enrolment portal</Typography>
            <Typography variant='medium' color='secondary'>
                configure email campaigns for employee and dependent enrolment to policies
            </Typography>
            <CompanyPoliciesComponent selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany}
                selectedPolicies={selectedPolicies} setSelectedPolicies={setSelectedPolicies}/>
            <DateCycleSelectionComponent memberRegistrationStartDate={memberRegistrationStartDate}
                setMemberRegistrationStartDate={setMemberRegistrationStartDate}
                memberRegistrationEndDate={memberRegistrationEndDate}
                setMemberRegistrationEndDate={setMemberRegistrationEndDate}
                endoCycleStartDate={endoCycleStartDate} setEndoCycleStartDate={setEndoCycleStartDate}
                endoCycleEndDate={endoCycleEndDate} setEndoCycleEndDate={setEndoCycleEndDate}/>
            <StyledButtonWrapper>
                <LoopButton variant={isShowPreviewEnabled?'filled':'disabled'} onClick={onShowPreviewClicked}>
                    Show preview
                </LoopButton>
            </StyledButtonWrapper>
        </StyledContainer>
    );
};
export default EnrolmentConfig;
