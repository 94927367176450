import React from 'react';
import { Button, PageHeader, Loader } from '../../../../atoms';
import { StyledInputContainer } from '../GlobalMemberSearch/styles';
import { StyledContainer } from './styles';
import { downloadHRMappings, updatePrevalidationAndAddHrRole } from '../../../../../adapters';
import { useToast } from '../../../../hooks/useToast';
import { StyledWrapper } from '../HrAddition/styles';
import { LoopButton } from '@loophealth/loop-ui-web-library';
import { excelDownloadUsingBlob } from '../../../../../utils/common';
// import { isSearchMemberByEmpIdFeatureEnabled } from '../../../../../utils/featureFlags';

const PreValidationAndHrAddition: React.FunctionComponent = () => {
    const toast = useToast();
    const [isPersistanceLoading, setIsPersistanceLoading] = React.useState(false);

    const onPreValidationAndHrAddition = async () => {
        setIsPersistanceLoading(true);
        const result = await updatePrevalidationAndAddHrRole();
        if (result.data.hrEmailOrMobileExist.length > 0) {
            const HRDetails = result.data.hrEmailOrMobileExist;
            toast?.displayToast('error', 'HR mobile or email already exists', HRDetails.join(','));
            setIsPersistanceLoading(false);
        }
        if (result.data.missingHrOrCompanyDetails.length > 0) {
            const missingHrOrCompanyDetails = result.data.missingHrOrCompanyDetails;
            toast?.displayToast('error', 'Missing company details', missingHrOrCompanyDetails.join(','));
            setIsPersistanceLoading(false);
        }
        if (result.data.policyNotfound.length > 0) {
            const policyNotfound = result.data.policyNotfound;
            toast?.displayToast('error', 'Policy not found', policyNotfound.join(','));
            setIsPersistanceLoading(false);
        }
        if (result.data.policyPrevalidationErrorList.length > 0) {
            const policyPrevalidationErrorList = result.data.policyPrevalidationErrorList;
            toast?.displayToast('error', 'Policy preValidation error', policyPrevalidationErrorList.join(','));
            setIsPersistanceLoading(false);
        }
        toast?.displayToast('success', 'Policy preValidation added successfully', '');
        setIsPersistanceLoading(false);
    };
    const url = process.env.REACT_APP_GS_PREVALIDATION_AND_HRD_SETUP_HRD_ACCESS_MANAGEMENT;


    const onHrMappings = async () => {
        const getRsp = await downloadHRMappings();
        excelDownloadUsingBlob(getRsp);
    };

    return (
        <StyledContainer>
            <div>
                <PageHeader>Policy Prevalidation and Hr addition</PageHeader>
            </div>
            <StyledInputContainer>
                {isPersistanceLoading ? (
                    <Loader />
                ) : (
                    <Button type="primary" onClick={onPreValidationAndHrAddition}>
                        Add PreValidation And Hr Data
                    </Button>
                )}
            </StyledInputContainer>
            <div>
                (<b>NOTE:</b>Above button will invoke Prevalidation and Hr addition script which will read data from
                {' '}
                <a href={url} target="_blank" rel="noreferrer">
                    excel
                </a>{' '}
                and store it in firebase database)
            </div>
            <StyledWrapper>
                <LoopButton variant="filled" onClick={onHrMappings}>
                    Download HR Mappings
                </LoopButton>
            </StyledWrapper>
        </StyledContainer>
    );
};

export default PreValidationAndHrAddition;
