import React, { useEffect } from 'react';
import {
    createColumnHelper, flexRender, getCoreRowModel,
    getSortedRowModel, SortingState, useReactTable
} from '@tanstack/react-table';
import {
    StyledFlexColMy4xAuto,
    StyledCol,
    StyledWCol,
    StyledNCol, StyledCheckboxColumn
} from './styles';
import {
    StyledColHead,
    StyledHeaderIcon,
    StyledTable,
    StyledTrHead,
    StyledTh,
    StyledTrBody,
    StyledTd
} from '../../../../../atoms';
import { CalendarIcon, ChevronRightIcon, ChevronDownIcon } from '../../../../../../assets/img';
import { IStatus, MemberDataEntity, MemberDataUIEntity } from '../../../../../../redux/slices/EndorsementSlice/types';
import { Bold } from '../../../../../atoms';
import { StyledTableMessage } from '../../../../../atoms/Table/styles';
import {
    transformMemberDataForUI
} from '../../../../../../utils/constants/Endorsement/transforms';
import IndeterminateCheckbox from '../../ChangeRequestsOfOneCompany/IndeterminateCheckbox';
import { Typography } from '@loophealth/loop-ui-web-library';

const columnHelper = createColumnHelper<MemberDataUIEntity>();
const checkBoxColumn = [columnHelper.display({
    id: 'select',
    header: ({ table }) => (
        <IndeterminateCheckbox
            {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler()
            }}
        />
    ),
    cell: ({ row }) => (
        <IndeterminateCheckbox
            {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler()
            }}
        />
    )
})
];
const tableColumns = [
    columnHelper.accessor('userId', {
        header: 'User ID',
        cell: (info) => {
            const name = info.getValue();
            return <Bold>{name !== '' ? name : '-'}</Bold>;
        },
        enableSorting: false
    }),
    columnHelper.accessor('employeeId', {
        header: 'Employee ID',
        enableSorting: false
    }),
    columnHelper.accessor('name', {
        header: 'Name',
        enableSorting: false
    }),
    columnHelper.accessor('createdAt', {
        header: () => <StyledColHead>Created on<StyledHeaderIcon src={CalendarIcon}/></StyledColHead>,
        enableSorting: false
    }),
    columnHelper.accessor('source', {
        header: 'Source',
        enableSorting: false
    }),
    columnHelper.accessor('operationType', {
        header: 'Operation type',
        enableSorting: false
    }),
    columnHelper.accessor('relationship', {
        header: 'Relationship',
        enableSorting: false
    }),
    columnHelper.accessor('updatedBy', {
        header: () => (
            <StyledColHead>
                Updated By
            </StyledColHead>
        ),
        enableSorting: false
    }),
    columnHelper.accessor('updatedAt', {
        header: () => <StyledColHead>Date of Change<StyledHeaderIcon src={CalendarIcon}/></StyledColHead>,
        enableSorting: false
    })
];

interface IMemberTableProps {
    isReassignAllowed: boolean
    data?: MemberDataEntity[];
    onRowSelectionChange: (selectedMembers: string[]) => void;
    handleOnRowClick: (row: MemberDataUIEntity) => void;
    status: IStatus
}

const MemberTable: React.FunctionComponent<IMemberTableProps> = ({
    data = [],
    onRowSelectionChange,
    isReassignAllowed,
    handleOnRowClick,
    status
}) => {
    const columns = isReassignAllowed ? [...checkBoxColumn, ...tableColumns] : tableColumns;
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [rowSelection, setRowSelection] = React.useState({});

    const table = useReactTable({
        data: transformMemberDataForUI(data, status),
        columns,
        state: {
            rowSelection,
            sorting
        },
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel()
    });

    useEffect(() => {
        const selectedRows = table.getSelectedRowModel().rows.map((r) => r.original);
        onRowSelectionChange(selectedRows.map((obj) => obj.id));
    }, [rowSelection]);

    const rows = table.getRowModel().rows;
    return (
        <StyledFlexColMy4xAuto>
            <StyledTable>
                <colgroup>
                    {isReassignAllowed && <StyledCheckboxColumn/>}
                    <StyledNCol/>
                    <StyledNCol/>
                    <StyledCol/>
                    <StyledCol/>
                    <StyledWCol/>
                    <StyledWCol/>
                    <StyledNCol/>
                    <StyledWCol/>
                </colgroup>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <StyledTrHead key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <StyledTh key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : <div
                                            {...{
                                                className: header.column.getCanSort()
                                                    ? 'cursor-pointer select-none'
                                                    : '',
                                                onClick: header.column.getToggleSortingHandler()
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: <StyledHeaderIcon width={24} src={ChevronRightIcon}/>,
                                                desc: <StyledHeaderIcon width={24} src={ChevronDownIcon}/>
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                    }
                                </StyledTh>
                            ))}
                        </StyledTrHead>
                    ))}
                </thead>
                <tbody>
                    {rows.length !== 0 ? (rows?.map((row) => (
                        <StyledTrBody
                            key={row.id}
                            onClick={() => handleOnRowClick(row.original)}
                            $hasError={row.original.isMidtermNonVerified}
                        >
                            {row.getVisibleCells().map((cell) => (
                                <StyledTd key={cell.id}>
                                    <Typography
                                        variant="medium"
                                        color={
                                            cell.column.id === 'userId' &&
                                            row.original.isMidtermNonVerified
                                                ? 'error'
                                                : 'primary'
                                        }
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </Typography>
                                </StyledTd>
                            ))}
                        </StyledTrBody>
                    ))) : null}
                </tbody>
            </StyledTable>
            {rows.length === 0 ? (
                <StyledTableMessage>
                    No Results! Try changing filters
                </StyledTableMessage>
            ) : null}
        </StyledFlexColMy4xAuto>
    );
};

export default MemberTable;
