import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    StyledDownloadDataContainer,
    StyledEndorsementExpandableContainer,
    StyledLiveBalance,
    StyledLivesContainer,
    StyledLivesSeparator,
    StyledSVG
} from './styles';
import { ChevronDownIcon, LoopEmeraldDownloadIcon } from '../../../../../assets/img';
import MemberTableContainer from './MemberTableContainer';
import { IEndorsementDataExpandableProps } from './types';
import { MemberDataEntity } from '../../../../../redux/slices/EndorsementSlice/types';
import { LoopButton } from '@loophealth/loop-ui-web-library';
import { isInsurerMemberDataFormatEnabled } from '../../../../../utils/featureFlags';
import useSegment from '../../../../../utils/segment/hooks/useSegment';
import { extractPathName, trackClickEvent } from '../../../../../utils/segment/utils';
import { useLocation } from 'react-router-dom';
import { checkIfMidtermNonVerified } from '../../../../../utils/constants/Endorsement';

const EndorsementDataExpandable: React.FunctionComponent<IEndorsementDataExpandableProps> = ({
    endorsementId,
    downloadMemberData,
    downloadInsurerMemberData,
    isMemberDownloadLoading,
    isInsurerMemberDownloadVisible,
    memberData,
    status,
    policyId,
    updateEndorsementMembers
}) => {
    const trackClick = useSegment('click');
    const location = useLocation();
    const endorsementLivesRef = useRef<HTMLDivElement | null>(null);
    const [isTableVisible, toggleTable] = useState(false);
    const uniqueLives: MemberDataEntity[] = Array.from(
        new Set((memberData as MemberDataEntity[]).map((item: any) => item.userId))
    );
    const {
        adds = 0,
        edits = 0,
        deletes = 0
    } = useMemo(() => {
        const lives = uniqueLives?.length;
        const adds = memberData?.filter((data) => data.operationType === 'ADD')?.length;
        const edits = Array.from(
            new Set(
                (memberData as MemberDataEntity[])
                    ?.filter((data) => data.operationType === 'EDIT')
                    .map((data: any) => data.userId)
            )
        )?.length;
        const deletes = memberData?.filter((data) => data.operationType === 'DELETE')?.length;
        return { lives, adds, edits, deletes };
    }, [memberData]);

    const expandMemberTable = () => {
        toggleTable((isTableVisible) => !isTableVisible);
        trackClick(trackClickEvent('Endo_Bucket_gen', location?.pathname, extractPathName(location?.pathname)));
    };

    const midTermNonVerifiedEntries = useMemo(
        () => memberData?.filter((member) => checkIfMidtermNonVerified(member, status)).length || 0,
        [memberData]
    );

    useEffect(() => {
        if (midTermNonVerifiedEntries) {
            setTimeout(() => {
                expandMemberTable();
                endorsementLivesRef.current?.scrollIntoView({
                    behavior: 'smooth'
                });
            }, 1000);
        }
    }, []);

    return (
        <>
            <StyledEndorsementExpandableContainer ref={endorsementLivesRef}>
                <StyledLivesContainer>
                    {/* <StyledEndorsementLives>{lives} Lives</StyledEndorsementLives>*/}
                    {/* <StyledSeparator />*/}
                    <StyledLiveBalance>
                        Addition: <span>{adds}</span> Lives
                    </StyledLiveBalance>
                    <StyledLivesSeparator />
                    <StyledLiveBalance>
                        Edits: <span>{edits}</span> Lives
                    </StyledLiveBalance>
                    <StyledLivesSeparator />
                    <StyledLiveBalance>
                        Deletion: <span>{deletes}</span> Lives
                    </StyledLiveBalance>
                </StyledLivesContainer>
                <StyledDownloadDataContainer>
                    <LoopButton onClick={downloadMemberData} variant="secondary" iconSrc={LoopEmeraldDownloadIcon}>
                        Download data
                    </LoopButton>
                    {isInsurerMemberDataFormatEnabled && isInsurerMemberDownloadVisible && (
                        <LoopButton
                            onClick={downloadInsurerMemberData}
                            variant="filled"
                            iconSrc={LoopEmeraldDownloadIcon}
                            isLoading={isMemberDownloadLoading}
                        >
                            Download Insurer Format Data
                        </LoopButton>
                    )}
                    <StyledSVG src={ChevronDownIcon} onClick={expandMemberTable} isExpanded={isTableVisible} />
                </StyledDownloadDataContainer>
            </StyledEndorsementExpandableContainer>
            {isTableVisible ? (
                <MemberTableContainer
                    memberData={memberData}
                    updateEndorsementMembers={updateEndorsementMembers}
                    policyId={policyId}
                    endorsementId={endorsementId}
                    status={status}
                    midTermNonVerifiedEntries={midTermNonVerifiedEntries}
                />
            ) : null}
        </>
    );
};
export default EndorsementDataExpandable;
