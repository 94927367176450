import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LabelButton, Loader, PageHeader } from '../../../../atoms';
import {
    StyledContainer,
    StyledCardContainer,
    StyledFormContainer,
    StyledCompanyContainer,
    StyledConfimBtnWrapper,
    StyledConfimBtnContainer,
    StyledIcon,
    StyledIconContainer,
    StyledErrorText,
    StyledPolicyStatusContainer,
    StyledFormWithButton
} from './styles';
import { CompanyCard } from '../../../../containers';
import { DynamicForms } from '../../../../containers';
import { IPlacement, placementDetails } from '../../../../../utils/constants/PlacementSlips/PlacementDetails';
import { policyTermsConditions } from '../../../../../utils/constants/PlacementSlips/PolicyTermsConditions';
import { slabDetails } from '../../../../../utils/constants/PlacementSlips/SlabDetails';
import { AnnouncementIcon, ChevronLeftIcon } from '../../../../../assets/img';
import { IPolicy, IPolicyTermsAndConditions, ISlab } from '../../../../../redux/slices/PolicySlice/types';
import { IDropdownData, placementMeta } from '../../../../../utils/constants/PlacementSlips/DropdownValues';
import { createPolicy, updatePolicy } from '../../../../../redux/slices/PolicySlice/thunk';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { resetCRUDState, setPlacementFormDetails } from '../../../../../redux/slices/PolicySlice';
import { slabObject } from '../../../../../utils/constants/PlacementSlips/SlabsObject';
import usePolicies from '../../../../hooks/business-hooks/usePolicies';
import {
    StyledPolicySubmitButton,
    StyledPolicySubmitButtonContainer
} from '../../../../containers/AddPolicy/PolicyForm/styles';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { StyledAlertData, StyledNotice } from '../AddPolicy/styles';

const PlacementForm: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const companyId = searchParams.get('id');
    const selectedCompany = useSelector(
        (state: ReduxState) => state.companySlice.companyList.data?.filter((company) => company.id === companyId)[0]
    );
    const policyId = searchParams.get('policyId');
    const [policyDetails, loading] = usePolicies([policyId as string]);
    const crudPolicy = useSelector((state: ReduxState) => state.policySlice.crudPolicy);
    const placementFormData = useSelector((state: ReduxState) => state.policySlice.placementFormDetails);
    const [updatedFormData, setUpdatedFormData] = React.useState<IPolicy | null>(placementFormData);
    const [validationErrorList, setValidationErrorsList] = React.useState<Record<string, string>>({});
    const [slabErrorList, setSlabErrorList] = React.useState<Record<string, Record<string, string>>>({});
    const [currentStep, setCurrentStep] = React.useState(1);
    let slabErrorCount = 0;

    Object.values(slabErrorList).map((item) => {
        slabErrorCount += Object.keys(item as Record<string, string>).length;
    });

    const isErrorRecorded = slabErrorCount + Object.keys(validationErrorList).length;
    React.useEffect(() => {
        if (policyDetails.length) {
            dispatch(setPlacementFormDetails({ placementFormDetails: policyDetails[0] }));
            setUpdatedFormData(policyDetails[0] as IPolicy);
        }
    }, [policyDetails, loading]);

    React.useEffect(() => {
        if (placementFormData && updatedFormData) {
            const updatePolicyId = { ...updatedFormData, id: placementFormData.id };
            setUpdatedFormData(updatePolicyId);
        }
    }, [placementFormData]);

    // Update Total lives when number of employees or dependents are updated
    React.useEffect(() => {
        if (updatedFormData) {
            const lives = (updatedFormData?.numberOfEmployees || 0) + (updatedFormData?.numberOfDependents || 0);
            const newObject = { ...updatedFormData, totalLives: lives };
            setUpdatedFormData(newObject);
            delete validationErrorList['totalLives'];
        }
    }, [updatedFormData?.numberOfEmployees, updatedFormData?.numberOfDependents]);

    // isCommonPool handling according to the sumInsuredApproach option selected in the form
    React.useEffect(() => {
        if ((updatedFormData?.sumInsuredApproach || '').toLowerCase() === 'individual') {
            const isCommonPool =
                (updatedFormData?.sumInsuredApproach || '').toLowerCase() === 'individual' ? false : true;
            const updatedGeneralDetails = { ...updatedFormData, ['isCommonPool']: isCommonPool };
            setUpdatedFormData(updatedGeneralDetails as unknown as IPolicy);
        }
    }, [updatedFormData?.sumInsuredApproach]);

    // installment Premium handling according to the option selected in the form
    React.useEffect(() => {
        let updateInstallments;
        if ((updatedFormData?.installmentPremium || '')?.toLowerCase() === 'annual') {
            updateInstallments = {
                ...updatedFormData,
                secondInstallmentAmount: 0,
                thirdInstallmentAmount: 0,
                fourthInstallmentAmount: 0
            };
        } else if ((updatedFormData?.installmentPremium || '')?.toLowerCase() === 'half-yearly') {
            updateInstallments = {
                ...updatedFormData,
                thirdInstallmentAmount: 0,
                fourthInstallmentAmount: 0
            };
        } else {
            updateInstallments = {
                ...updatedFormData,
                firstInstallmentAmount: 0,
                secondInstallmentAmount: 0,
                thirdInstallmentAmount: 0,
                fourthInstallmentAmount: 0
            };
        }
        delete validationErrorList['firstInstallmentAmount'];
        delete validationErrorList['secondInstallmentAmount'];
        delete validationErrorList['thirdInstallmentAmount'];
        delete validationErrorList['fourthInstallmentAmount'];
        setUpdatedFormData(updateInstallments as unknown as IPolicy);
    }, [updatedFormData?.installmentPremium]);

    // If number of slabs is increased, add the additional slabs
    React.useEffect(() => {
        addSlab(updatedFormData?.policyTermsAndConditions.numberOfSlabs || 0);
    }, [updatedFormData?.policyTermsAndConditions?.numberOfSlabs]);

    // Handle isCommonPool if sumInsuredApproach is changed
    React.useEffect(() => {
        let newObj = { ...updatedFormData };
        if (updatedFormData?.sumInsuredApproach === 'Individual') {
            newObj = { ...updatedFormData, isCommonPool: false };
        } else {
            newObj = { ...updatedFormData, isCommonPool: true };
        }
        setUpdatedFormData(newObj as IPolicy);
    }, [updatedFormData?.sumInsuredApproach]);

    // Handle slab numbers when sumInsuredStructure is updated
    React.useEffect(() => {
        if (
            updatedFormData &&
            (updatedFormData?.policyTermsAndConditions?.sumInsuredStructure || '').toLowerCase() === 'flat sum insured'
        ) {
            const updatedTermsAndConditions = { ...updatedFormData.policyTermsAndConditions, ['numberOfSlabs']: 1 };
            const newObj = { ...updatedFormData, policyTermsAndConditions: updatedTermsAndConditions };
            setUpdatedFormData(newObj as unknown as IPolicy);
        }
    }, [updatedFormData?.policyTermsAndConditions?.sumInsuredStructure]);

    // Scroll to the top of the every form
    React.useEffect(() => {
        scrollRef.current?.scrollIntoView();
        if (currentStep > 3) {
            history.replaceState(null, '', '/company-directory');
            navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.COMPANY_DETAILS.route + '?id=' + companyId);
            location.reload();
        }
    }, [currentStep, scrollRef.current]);

    // Generate empty Slabs if the numberOfSlabs is mentioned in the form but the slabs are empty
    React.useEffect(() => {
        if (
            updatedFormData &&
            updatedFormData?.sumAssuredSlabs.length < updatedFormData?.policyTermsAndConditions.numberOfSlabs
        ) {
            addSlab(updatedFormData?.policyTermsAndConditions?.numberOfSlabs);
        }
    }, [updatedFormData?.sumAssuredSlabs]);

    React.useEffect(() => {
        if (crudPolicy.error?.message.includes('nickname')) {
            setValidationErrorsList({
                ...validationErrorList,
                nickName: 'Policy nickname already exists. Refer guidelines to create a unique nickname.'
            });
        } else {
            delete validationErrorList['nickName'];
            setValidationErrorsList({
                ...validationErrorList
            });
        }
    }, [crudPolicy.error]);

    const getPlacmentCustomFields = (): IPlacement[] => {
        if (currentStep === 1) {
            const customFields: IPlacement[] = placementDetails;
            const firstInstallmentIndex = customFields.findIndex((field) => field.name === 'firstInstallmentAmount');
            const secondInstallmentIndex = customFields.findIndex((field) => field.name === 'secondInstallmentAmount');
            const thirdInstallmentIndex = customFields.findIndex((field) => field.name === 'thirdInstallmentAmount');
            const fourthInstallmentIndex = customFields.findIndex((field) => field.name === 'fourthInstallmentAmount');

            if ((updatedFormData?.installmentPremium || '')?.toLowerCase() === 'annual') {
                placementDetails[firstInstallmentIndex].fullWidth = true;
                placementDetails[secondInstallmentIndex].display = false;
                placementDetails[thirdInstallmentIndex].display = false;
                placementDetails[fourthInstallmentIndex].display = false;
            } else if ((updatedFormData?.installmentPremium || '')?.toLowerCase() === 'half-yearly') {
                placementDetails[firstInstallmentIndex].fullWidth = false;
                placementDetails[secondInstallmentIndex].display = true;
                placementDetails[thirdInstallmentIndex].display = false;
                placementDetails[fourthInstallmentIndex].display = false;
            } else if ((updatedFormData?.installmentPremium || '')?.toLowerCase() === 'quarterly') {
                placementDetails[secondInstallmentIndex].display = true;
                placementDetails[thirdInstallmentIndex].display = true;
                placementDetails[fourthInstallmentIndex].display = true;
            }
            return placementDetails.filter(
                (obj) => placementFormData && obj.availability?.includes(placementFormData?.policyType) && obj.display
            );
        } else if (currentStep === 2) {
            if (
                (updatedFormData?.policyTermsAndConditions?.sumInsuredStructure || '').toLowerCase() ===
                'flat sum insured'
            ) {
                const index = policyTermsConditions[placementFormData?.policyType.toLowerCase() || ''].findIndex(
                    (field) => field.name === 'numberOfSlabs'
                );
                policyTermsConditions[placementFormData?.policyType.toLowerCase() || ''][index].isDisabled = true;
            } else {
                const index = policyTermsConditions[placementFormData?.policyType.toLowerCase() || ''].findIndex(
                    (field) => field.name === 'numberOfSlabs'
                );
                policyTermsConditions[placementFormData?.policyType.toLowerCase() || ''][index].isDisabled = false;
            }
            return policyTermsConditions[placementFormData?.policyType.toLowerCase() || ''];
        } else if (currentStep === 3) {
            return slabDetails[placementFormData?.policyType.toLowerCase() || ''];
        }
        return [];
    };

    const getFormData = (form: IPolicy | null): Record<string, unknown> => {
        if (currentStep === 1) {
            return form as unknown as Record<string, unknown>;
        } else if (currentStep === 2) {
            const updatedTermsAndConditions = {
                ...form?.policyTermsAndConditions,
                sumInsuredApproach: form?.sumInsuredApproach
            };
            return updatedTermsAndConditions as unknown as Record<string, unknown>;
        } else if (currentStep === 3) {
            return form?.sumAssuredSlabs as unknown as Record<string, unknown>;
        }
        return {};
    };

    const getOptions = (type: string, fieldName: string): IDropdownData[] | [] => {
        if (type.toLowerCase() === 'dropdown') {
            const policyType = placementFormData?.policyType;
            let optionsAvaialable: IDropdownData[] | [] = [];
            if (policyType) {
                if (placementFormData?.isParentalPolicy) {
                    const filterList = ['Parents Only', 'Parents (including in-laws)',
                        'Parents OR parents-in-law', 'Parents + Parents-in-law'];
                    const data = placementMeta[fieldName].
                        filter((obj) => obj.availablility?.includes(policyType));
                    optionsAvaialable = data.filter((item) => filterList.includes(item.name));
                } else {
                    optionsAvaialable = placementMeta[fieldName].
                        filter((obj) => obj.availablility?.includes(policyType));
                }
            }
            if (optionsAvaialable?.length > 0) {
                return optionsAvaialable;
            } else {
                return placementMeta[fieldName] || [];
            }
        } else {
            return [];
        }
    };

    const updatedFormObject = (key: string, value: string | number, index?: number) => {
        let newObj = {};
        const existingSlabs = Object.assign([], updatedFormData?.sumAssuredSlabs || []);

        if (currentStep === 1 || key === 'sumInsuredApproach') {
            newObj = { ...updatedFormData, [`${key}`]: value };
        } else if (currentStep === 2) {
            newObj = { ...updatedFormData?.policyTermsAndConditions, [`${key}`]: value };
        } else if (currentStep === 3) {
            if (index !== undefined && updatedFormData) {
                const updatedObj = { ...updatedFormData?.sumAssuredSlabs?.[index], [`${key}`]: value };
                existingSlabs.splice(index, 1, updatedObj);
                newObj = existingSlabs;
            }
        }
        if (currentStep !== 3) return newObj;
        else return existingSlabs;
    };

    const updateFormData = (newObj: Record<string, unknown>, key: string) => {
        if (currentStep === 1 || key === 'sumInsuredApproach') {
            setUpdatedFormData(newObj as unknown as IPolicy);
        } else if (currentStep === 2) {
            const updatedObj = { ...updatedFormData, policyTermsAndConditions: newObj };
            setUpdatedFormData(updatedObj as unknown as IPolicy);
        } else if (currentStep === 3) {
            const updatedObj = { ...updatedFormData, sumAssuredSlabs: newObj };
            setUpdatedFormData(updatedObj as unknown as IPolicy);
        }
    };

    const addSlab = (value: number) => {
        const existingSlabCount = updatedFormData?.sumAssuredSlabs.length || 0;
        const slabs = Object.assign([], updatedFormData?.sumAssuredSlabs || []);

        if ((value as number) > (existingSlabCount || 0) && placementFormData) {
            for (let i = existingSlabCount + 1; i <= (value as number); i++) {
                const slab: ISlab =
                    placementFormData?.policyType && slabObject[placementFormData?.policyType.toLowerCase()];
                Object.keys(slab).map((key) => {
                    if (key === 'slabId') {
                        (slab as ISlab)['slabId'] = i.toString();
                    }
                });
                slabs.push({ ...slab } as ISlab);
            }

            const updatedObj = { ...updatedFormData, sumAssuredSlabs: slabs };
            setUpdatedFormData(updatedObj as unknown as IPolicy);
        } else if (value) {
            // Updated form data as per the number of slabs selected
            const updatedForm = Object.assign([], updatedFormData?.sumAssuredSlabs || []);
            const slabCount = Number(updatedFormData?.policyTermsAndConditions?.numberOfSlabs || 0);
            updatedForm.length = slabCount;
            const updatedObj = { ...updatedFormData, sumAssuredSlabs: updatedForm };
            setUpdatedFormData(updatedObj as unknown as IPolicy);
        }
    };

    const handleFormNavigation = (back?: boolean) => {
        if (back) {
            setCurrentStep(currentStep - 1);
            setValidationErrorsList({});
            setSlabErrorList({});
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const saveChanges = () => {
        if (updatedFormData) {
            dispatch(resetCRUDState());
            const { policyTermsAndConditions, sumAssuredSlabs, ...policyCommon } = updatedFormData;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { insurerLogo, ...newpolicyCommon } = policyCommon;
            const newCommonPolicy = {
                ...newpolicyCommon,
                finalAgreedRate: updatedFormData.finalAgreedRate ? updatedFormData.finalAgreedRate : null,
                numberOfDependents: updatedFormData.numberOfDependents ? updatedFormData.numberOfDependents : 0,
                installmentPremium: updatedFormData.installmentPremium ? updatedFormData.installmentPremium : '',
                firstInstallmentAmount: updatedFormData.firstInstallmentAmount
                    ? updatedFormData.firstInstallmentAmount
                    : null
            };
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { sumInsuredApproach, ...newPolicyTermsAndConditions } =
                policyTermsAndConditions as unknown as Record<string, unknown>;
            if (currentStep === 1 && !updatedFormData.id) {
                dispatch(
                    createPolicy({
                        policyData: {
                            ...policyCommon,
                            sumAssuredSlabs,
                            // nickName: policyNickname,
                            policyTermsAndConditions:
                                newPolicyTermsAndConditions as unknown as IPolicyTermsAndConditions
                        } as IPolicy,
                        handleFormNavigation
                    })
                );
            } else if (currentStep === 2 || (currentStep === 1 && updatedFormData.id)) {
                dispatch(
                    updatePolicy({
                        policyData: {
                            ...newCommonPolicy,
                            // nickName: policyNickname,
                            sumAssuredSlabs: sumAssuredSlabs,
                            policyTermsAndConditions:
                                newPolicyTermsAndConditions as unknown as IPolicyTermsAndConditions
                        } as IPolicy,
                        handleFormNavigation
                    })
                );
            } else if (currentStep === 3) {
                const updatedStatus = { ...newCommonPolicy, policyPlacementStatus: 'Handover to Ops' };
                dispatch(
                    updatePolicy({
                        policyData: {
                            ...updatedStatus,
                            sumAssuredSlabs,
                            policyTermsAndConditions:
                                newPolicyTermsAndConditions as unknown as IPolicyTermsAndConditions
                        } as IPolicy,
                        handleFormNavigation
                    })
                );
            }
        }
    };
    const changeStatusToDraft = () => {
        if (updatedFormData) {
            setUpdatedFormData({
                ...updatedFormData,
                policyPlacementStatus: 'Draft'
            });
        }
    };

    const onChange = (formChanges: Record<string, string | number>, index?: number) => {
        const updatedField = getPlacmentCustomFields().filter((field) => field.name === Object.keys(formChanges)[0]);
        const errorList: Record<string, string> = { ...validationErrorList };

        let newObj = updatedFormObject(Object.keys(formChanges)[0], Object.values(formChanges)[0], index);
        let typeCasting;

        const checkValue =
            index !== undefined
                ? !(newObj as unknown as Record<string, Record<string, unknown>>)[index][updatedField?.[0]?.name]
                : !(newObj as unknown as Record<string, unknown>)[updatedField?.[0]?.name];

        // To check if the field is mandatory
        if (updatedField?.[0]?.isMandatory && checkValue) {
            // Slab Specific error handling
            if (index !== undefined) {
                handleSlabErrors(updatedField?.[0], index);
            } else {
                errorList[updatedField?.[0]?.name] = `${updatedField?.[0]?.label} cannot be Empty`;
            }
        } else if (updatedField?.[0]?.inputType === 'number') {
            // To check if the data type of the entered number fields is correct
            typeCasting = Number(formChanges[Object.keys(formChanges)[0]] as string);
            if (!isNaN(typeCasting)) {
                newObj = updatedFormObject(Object.keys(formChanges)[0], typeCasting, index);
                if (index !== undefined) {
                    handleSlabErrors(updatedField?.[0], index, true);
                } else {
                    delete errorList[Object.keys(formChanges)[0]];
                }
            } else {
                if (index !== undefined) {
                    handleSlabErrors(updatedField?.[0], index, false, true);
                } else {
                    errorList[Object.keys(formChanges)[0]] = `Please provide a valid ${updatedField?.[0]?.label}`;
                }
            }
        } else {
            if (index !== undefined) {
                handleSlabErrors(updatedField?.[0], index, true);
            } else {
                delete errorList[Object.keys(formChanges)[0]];
            }
        }
        updateFormData(newObj, Object.keys(formChanges)[0]);
        setValidationErrorsList(errorList);
    };

    const handleSlabErrors = (updatedField: IPlacement, index: number, removeError?: boolean, validation?: boolean) => {
        const existingErrors = { ...slabErrorList };
        const slabErrors: Record<string, string> = { ...slabErrorList[index] };
        if (!removeError) {
            validation
                ? (slabErrors[`${updatedField?.name}`] = `Please provide a valid ${updatedField?.label}`)
                : (slabErrors[`${updatedField?.name}`] = `${updatedField?.label} cannot be Empty`);
            existingErrors[index] = slabErrors;
        } else {
            delete slabErrors[updatedField.name];
            existingErrors[index] = slabErrors;
        }
        setSlabErrorList(existingErrors);
    };

    const handleSlabSubmit = () => {
        const existingErrors = { ...slabErrorList };
        const slabs = getFormData(updatedFormData);
        const slabCount = Number(updatedFormData?.policyTermsAndConditions?.numberOfSlabs || 0);

        for (let i = 0; i < slabCount; i++) {
            const errorList: Record<string, string> = { ...slabErrorList[i] };
            getPlacmentCustomFields().map((item) => {
                if (item.isMandatory && !(slabs?.[i] as Record<string, string>)?.[item.name]) {
                    errorList[item.name] = `${item.label} cannot be Empty`;
                } else if (item.inputType === 'number' && getFormData(updatedFormData)[item.name]) {
                    const typeCasting = parseInt(
                        (getFormData(updatedFormData) as unknown as Record<string, string>)[item.name]
                    );
                    if (isNaN(typeCasting)) {
                        errorList[item.name] = `Please provide a valid ${item.label}`;
                        setValidationErrorsList(errorList);
                    }
                } else if (item.type === 'dropdown' && getFormData(updatedFormData)[item.name]) {
                    const opt: IDropdownData[] | [] = getOptions(item.type, item.name);
                    const selectedValue = getFormData(updatedFormData)[item.name];
                    const checkValues = (obj: IDropdownData) => obj.value === selectedValue;
                    if (!opt.some(checkValues)) {
                        errorList[item.name] = `Please select a valid ${item.label}`;
                        setValidationErrorsList(errorList);
                    }
                } else {
                    delete errorList[item.name];
                }
            });
            existingErrors[i] = errorList;
        }
        setSlabErrorList(existingErrors);
        return existingErrors;
    };

    const handleSubmit = () => {
        if (updatedFormData) {
            delete updatedFormData.policyDocuments;
            setUpdatedFormData({
                ...updatedFormData,
                cdAmount: updatedFormData?.cdAmount ? +updatedFormData?.cdAmount : 0,
                gst: updatedFormData?.gst ? +updatedFormData?.gst : 0
            });
            const errorList: Record<string, string> = { ...validationErrorList };
            let slabErrors = {};
            if (currentStep === 3) {
                slabErrors = handleSlabSubmit();
            } else {
                getPlacmentCustomFields().map((item) => {
                    if (item.isMandatory && !(getFormData(updatedFormData)?.[item.name] as string)?.toString().length) {
                        errorList[item.name] = `${item.label} cannot be Empty`;
                    } else if (item.inputType === 'number' && getFormData(updatedFormData)[item.name]) {
                        const typeCasting = parseInt(
                            (getFormData(updatedFormData) as unknown as Record<string, string>)[item.name]
                        );
                        const policyStartDate = updatedFormData.policyStartDate.toDateString();
                        const policyEndDate = updatedFormData.policyEndDate.toDateString();
                        if (policyStartDate === policyEndDate) {
                            errorList['policyStartDate'] = `Policy start and end date cannot be same`;
                            errorList['policyEndDate'] = `Policy start and end date cannot be same`;
                            setValidationErrorsList(errorList);
                        }
                        if (isNaN(typeCasting)) {
                            errorList[item.name] = `Please provide a valid ${item.label}`;
                            setValidationErrorsList(errorList);
                        }
                    } else if (item.type === 'dropdown' && getFormData(updatedFormData)[item.name]) {
                        const opt: IDropdownData[] | [] = getOptions(item.type, item.name);
                        const selectedValue = getFormData(updatedFormData)[item.name];
                        const checkValues = (obj: IDropdownData) => obj.value === selectedValue;
                        if (!opt.some(checkValues)) {
                            errorList[item.name] = `Please select a valid ${item.label}`;
                            setValidationErrorsList(errorList);
                        }
                    } else {
                        delete errorList[item.name];
                    }
                });
            }
            setValidationErrorsList(errorList);
            let slabErrorCount = 0;
            Object.values(slabErrors).map((item) => {
                slabErrorCount += Object.keys(item as Record<string, string>).length;
            });
            if (Object.keys(errorList).length === 0 && slabErrorCount === 0) {
                saveChanges();
            }
        }
    };

    return (
        (updatedFormData && selectedCompany && (
            <StyledContainer ref={scrollRef}>
                {
                    currentStep === 1 && <StyledNotice>
                        <img src={AnnouncementIcon} />
                        <StyledAlertData>
                            <Typography variant='medium' weight='bold' color='emerald'>
                                Mandatory &apos;Policy Nickname&apos; Field Added
                            </Typography>
                            <Typography variant='medium'>
                                Policy Nickname is now a required field in the policy addition process.
                                Ensure you assign a unique nickname as per the guidelines mentioned.
                            </Typography>
                        </StyledAlertData>
                    </StyledNotice>
                }
                <PageHeader>PLACEMENT FORM {placementFormData?.id && `: ${placementFormData?.id}`}</PageHeader>
                {currentStep != 1 ? (
                    <StyledIconContainer onClick={() => handleFormNavigation(true)}>
                        <StyledIcon src={ChevronLeftIcon} alt={'Back Icon'} />
                        <Typography variant='medium' color='secondary'>Back</Typography>
                    </StyledIconContainer>
                ) : null}
                <StyledCardContainer>
                    <StyledFormWithButton>
                        <StyledFormContainer>
                            {updatedFormData?.policyPlacementStatus === 'Handover to Ops' && (
                                <StyledPolicyStatusContainer>
                                    <LabelButton isInverted onClick={changeStatusToDraft}>
                                        {crudPolicy.loading ? <Loader inverted={false} /> : 'Change Status to DRAFT'}
                                    </LabelButton>
                                </StyledPolicyStatusContainer>
                            )}
                            {currentStep === 1 && (
                                <DynamicForms
                                    customFieldData={getPlacmentCustomFields()}
                                    formData={getFormData(updatedFormData)}
                                    title={'General Details'}
                                    onChange={
                                        (formChanges: Record<string, string | number>) => onChange(formChanges)
                                    }
                                    validationErrorList={validationErrorList}
                                    options={(type: string, fieldName: string) => getOptions(type, fieldName)}
                                />
                            )}
                            {currentStep === 2 && (
                                <DynamicForms
                                    customFieldData={getPlacmentCustomFields()}
                                    formData={getFormData(updatedFormData)}
                                    title={`${updatedFormData?.policyType} General Terms and Conditions`}
                                    onChange={(formChanges: Record<string, string | number>) => onChange(formChanges)}
                                    validationErrorList={validationErrorList}
                                    options={(type: string, fieldName: string) => getOptions(type, fieldName)}
                                />
                            )}
                            {currentStep === 3 &&
                                Array.from(
                                    Array(updatedFormData?.policyTermsAndConditions.numberOfSlabs),
                                    (element, index) => {
                                        if (!getFormData(updatedFormData)[index]) {
                                            return <></>;
                                        }
                                        return (
                                            <DynamicForms
                                                key={index}
                                                customFieldData={getPlacmentCustomFields()}
                                                formData={
                                                    getFormData(updatedFormData)[index] as Record<string, unknown>
                                                }
                                                title={`Slab Specific Terms - Slab ${
                                                    (getFormData(updatedFormData)[index] as unknown as ISlab).slabId ||
                                                    ''
                                                }`}
                                                onChange={(formChanges: Record<string, string | number>) =>
                                                    onChange(formChanges, index)
                                                }
                                                validationErrorList={slabErrorList[index]}
                                                options={(type: string, fieldName: string) =>
                                                    getOptions(type, fieldName)
                                                }
                                            />
                                        );
                                    }
                                )}
                            {isErrorRecorded ? (
                                <StyledErrorText> Please fix all the form errors </StyledErrorText>
                            ) : null}
                            {crudPolicy.error?.message && !(crudPolicy.error?.message.includes('nickname')) ? (
                                <StyledErrorText> {crudPolicy.error.message} </StyledErrorText>
                            ) : null}
                        </StyledFormContainer>
                        <StyledPolicySubmitButtonContainer>
                            <StyledPolicySubmitButton>
                                <StyledConfimBtnContainer>
                                    <StyledConfimBtnWrapper>
                                        <LoopButton
                                            isLoading={crudPolicy.loading}
                                            variant="filled"
                                            onClick={handleSubmit}
                                        >
                                            {currentStep !== 3 ? (
                                                'Continue'
                                            ) : (
                                                'Submit'
                                            )}
                                        </LoopButton>
                                    </StyledConfimBtnWrapper>
                                </StyledConfimBtnContainer>
                            </StyledPolicySubmitButton>
                        </StyledPolicySubmitButtonContainer>
                    </StyledFormWithButton>
                    <StyledCompanyContainer>
                        <CompanyCard {...selectedCompany} isPlacementForm={true} />
                    </StyledCompanyContainer>
                </StyledCardContainer>
            </StyledContainer>
        )) || <Loader />
    );
};

export default PlacementForm;
