import React from 'react';
import { Button, PageHeader, Loader } from '../../../../atoms';
import { StyledInputContainer } from '../GlobalMemberSearch/styles';
import { StyledContainer } from './styles';
import { addPrevalidationToPolicy } from '../../../../../adapters';
import { useToast } from '../../../../hooks/useToast';
// import { isSearchMemberByEmpIdFeatureEnabled } from '../../../../../utils/featureFlags';

const PreValidationAndHrAddition: React.FunctionComponent = () => {
    const toast = useToast();
    const [isPersistanceLoading, setIsPersistanceLoading] = React.useState(false);

    const onPreValidation = async () => {
        setIsPersistanceLoading(true);
        const result = await addPrevalidationToPolicy();
        if (result.data.policyNotfound.length > 0) {
            const policyNotfound = result.data.policyNotfound;
            toast?.displayToast('error', 'Policy not found', policyNotfound.join(','));
            setIsPersistanceLoading(false);
        }
        if (result.data.policyPrevalidationErrorList.length > 0) {
            const policyPrevalidationErrorList = result.data.policyPrevalidationErrorList;
            toast?.displayToast('error', 'Policy preValidation error', policyPrevalidationErrorList.join(','));
            setIsPersistanceLoading(false);
        }
        if (
            result.data.policyPrevalidationErrorList.length == 0 &&
            result.data.policyNotfound.length == 0 &&
            result.data.preValidationAdded.length == 0
        ) {
            toast?.displayToast('error', 'Please check excel of prevalidation', '');
            setIsPersistanceLoading(false);
        }
        toast?.displayToast('success', 'Policy preValidation added successfully', '');
        setIsPersistanceLoading(false);
    };
    const url = process.env.REACT_APP_GS_PREVALIDATION_AND_HRD_SETUP_PREVALIDATION;
    return (
        <StyledContainer>
            <div>
                <PageHeader>Add Policy Prevalidation </PageHeader>
            </div>
            <StyledInputContainer>
                {isPersistanceLoading ? (
                    <Loader />
                ) : (
                    <Button type="primary" onClick={onPreValidation}>
                        Add PreValidation for Policies
                    </Button>
                )}
            </StyledInputContainer>
            <div>
                (<b>NOTE:</b>Above button will invoke Prevalidation script which will read data from{' '}
                <a href={url} target="_blank" rel="noreferrer">
                    excel
                </a>{' '}
                and store it in firebase database)
            </div>
        </StyledContainer>
    );
};

export default PreValidationAndHrAddition;
