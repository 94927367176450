import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReduxState } from '../../../../../redux';
import { Table, TableHeading, TableRow, Card, PrimaryPill, LabelButton } from '../../../../atoms';
import {
    StyledContainer,
    StyledTableContainer,
    StyledTitle,
    StyledTitleContainer,
    StyledTitleCaption,
    StyledMetaRow,
    StyledDownloadImage,
    StyledLabelContainer,
    StyledSubTitle,
    StyledMemberTableHeader,
    StyledUserPageActions,
    StyledSearchMembers,
    StyledSelectPolicy,
    StyledMemberTableTitle
} from './styles';
import { DownloadIcon } from '../../../../../assets/img';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { excelDownload } from '../../../../../utils/common';
import { DateFormatUtils, Form, Typography } from '@loophealth/loop-ui-web-library';
import useFetchUsersByPolicy from '../../../../hooks/business-hooks/useFetchUsersByPolicy';
import useFetchPolicyDropdownList from '../../../../hooks/business-hooks/useFetchPolicyDropdownList';
import useSearchUsers from '../../../../hooks/business-hooks/useSearchUsers';
import MemberDetailsTableBody from './MemberDetailsTableBody';

const MemberDetails: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    // get the company for which we want to display members for
    const companyId = searchParams.get('id');
    const company = useSelector((state: ReduxState) => {
        const list = state.companySlice.companyList;
        return {
            data: (list.data || []).filter((company) => company.id === companyId)[0],
            loading: list.loading,
            error: list.error
        };
    });
    // states for actions on member details page
    const policyList = useFetchPolicyDropdownList(companyId);
    const [selectedPolicy, setSelectedPolicy] = React.useState<string>('All');
    // users to fetch for company or policy
    const users = useFetchUsersByPolicy({
        companyId,
        policyId: selectedPolicy === 'All' ? null : selectedPolicy
    });
    // handle search on above users
    const { handleSearch, searchedText, searchedUsers, shouldShowSearch } = useSearchUsers(users.data);
    const routeToCompanyDetails = () => {
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.COMPANY_DETAILS.route + '?id=' + companyId);
    };
    const formatPolicyDropdownList = () => [
        { name: 'All', value: 'All' },
        ...policyList.data.map((v) => ({
            ...v,
            name: `${v.name.policyType} - ${v.name.id} - (${v.name.nickName})`
        }))
    ];
    const handleDownload = () => {
        if (users.data) {
            const userData: Record<string, unknown>[] = [];
            users.data.map((user) => {
                let userDetails: Record<string, unknown> = {
                    'User ID': user.userId,
                    'Employee ID': user.employeeId,
                    'Name': user.firstName + ' ' + user.lastName,
                    'Relationship': user.relationship,
                    'DOB': user.dob ? new DateFormatUtils().formatDate(user.dob) : null,
                    'Gender': user.gender,
                    'Phone': user.mobile,
                    'Email': user.email,
                    'Parent User ID': user.parentId
                };
                if (selectedPolicy !== 'All') {
                    userDetails = {
                        'Active': new Date() <= user.policyEndDate ? 'Yes' : 'No',
                        ...userDetails,
                        'Policy ID': user.policyId,
                        'Policy Nick Name': user.policyNickName,
                        'Coverage Start Date': new DateFormatUtils().formatDate(user.policyStartDate),
                        'Coverage End Date': new DateFormatUtils().formatDate(user.policyEndDate),
                        'Exception': user.isException ? 'Yes' : 'No',
                        'UHID': user.uhid,
                        'Sum Insured': user.sumInsured,
                        'Endo Cycle': user.endoCycle
                    };
                }
                userData.push(userDetails);
            });
            const fileName = `MEMBER DETAILS-${company.data.name.replace(/./g, '')}-${new Date().toLocaleString()}`;
            excelDownload(userData, fileName);
        }
    };

    return (
        <StyledContainer>
            <div>
                <Card>
                    <StyledLabelContainer>
                        <LabelButton onClick={routeToCompanyDetails}>View Policies &#8680;</LabelButton>
                    </StyledLabelContainer>
                    <StyledTitleContainer>
                        <StyledTitleCaption>Company Name</StyledTitleCaption>
                        <StyledTitle>{company?.data?.name}</StyledTitle>
                        <StyledTitleCaption>{companyId}</StyledTitleCaption>
                    </StyledTitleContainer>
                    <StyledMetaRow>
                        <PrimaryPill title="Lives">
                            <StyledSubTitle>{`${(users.data || []).length}`}</StyledSubTitle>
                        </PrimaryPill>
                        <PrimaryPill title="Employees">
                            <StyledSubTitle>
                                {((users.data || []).filter((usr) => usr.relationship === 'self') || []).length}
                            </StyledSubTitle>
                        </PrimaryPill>
                        <PrimaryPill title="Dependents">
                            <StyledSubTitle>
                                {((users.data || []).filter((usr) => usr.relationship !== 'self') || []).length}
                            </StyledSubTitle>
                        </PrimaryPill>
                    </StyledMetaRow>
                </Card>
            </div>
            <StyledTableContainer>
                <StyledMemberTableHeader>
                    <StyledMemberTableTitle>
                        <Typography variant="title2" weight="bold">
                            MEMBER DETAILS
                        </Typography>
                        <StyledDownloadImage
                            title="Download Member Details"
                            src={DownloadIcon}
                            onClick={handleDownload}
                        />
                    </StyledMemberTableTitle>
                    <StyledUserPageActions>
                        {(shouldShowSearch && (
                            <StyledSearchMembers>
                                <Form.Input
                                    value={searchedText}
                                    name="search-member"
                                    placeholder={'Search Members'}
                                    onChange={handleSearch}
                                />
                            </StyledSearchMembers>
                        )) || <></>}
                        <StyledSelectPolicy>
                            <Form.FormField id="policy-list-dropdown" label="Select a policy">
                                <Form.Dropdown
                                    listItems={formatPolicyDropdownList()}
                                    onClick={(value) => setSelectedPolicy(value)}
                                    selectedItem={selectedPolicy}
                                    placeholder="Please Select"
                                ></Form.Dropdown>
                            </Form.FormField>
                        </StyledSelectPolicy>
                    </StyledUserPageActions>
                </StyledMemberTableHeader>
                <Table>
                    <TableRow>
                        <TableHeading>#</TableHeading>
                        <TableHeading index>Name</TableHeading>
                        <TableHeading>Contact</TableHeading>
                        <TableHeading align="center">REL</TableHeading>
                        <TableHeading align="center">Gender</TableHeading>
                        <TableHeading align="center">DOB</TableHeading>
                        <TableHeading align="center">EmployeeID</TableHeading>
                        <TableHeading align="center">ParentID</TableHeading>
                        <TableHeading align="center">Status</TableHeading>
                        <TableHeading align="center">Actions</TableHeading>
                    </TableRow>
                    <MemberDetailsTableBody
                        company={company}
                        searchedText={searchedText}
                        searchedUsers={searchedUsers}
                        users={users}
                    />
                </Table>
            </StyledTableContainer>
        </StyledContainer>
    );
};

export default React.memo(MemberDetails);
