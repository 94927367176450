import styled from 'styled-components';

export const StyledEnrolmentReportContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 20px;
    flex-direction: column;
    box-sizing: border-box;
`;
export const StyledContainer = styled.div`
    width: 100%;
`;

export const StyledFormContainer = styled.div`
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    margin: 25px 0px;
    margin-bottom: 35px;
`;
export const StyledFormFieldWidth = styled.div`
    width: 400px;
`;
