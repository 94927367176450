import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BackIcon, DeleteIcon } from '../../../../../assets/img';
import {
    StyledContainer,
    StyledHeader,
    StyledTitleWrapper,
    EndorsementDetailsWrapper,
    StyledButtonWrapper
} from './styles';
import {
    IEndorsement,
    IStatus,
    MemberDataEntity,
    NoteEntity
} from '../../../../../redux/slices/EndorsementSlice/types';
import EndorsementStateComponent from './EndorsementStateComponent';
import EndorsementOverview from './EndorsementOverview';
import CDBalanceOverview from './CDBalanceOverview';
import NotesContainer from './NotesContainer';
import EndorsementDataExpandable from './EndorsementDataExpandable';
import { downloadBlobAsFile, parseResponse, showApiFailureToast } from '../../../../../utils/common';
import { Loader } from '../../../../atoms';
import { StyledTableLoaderContainer } from '../Endorsements/styles';
import { downloadMemberdata, fetchCdBalanceOverviewById, fetchEndorsementByID } from '../../../../../adapters';
import { useToast } from '../../../../hooks/useToast';
import { IEndorsementStatusUpdateResponse } from '../../../../containers/ChangeEndorsementStatusModal/types';
import { ICDBalanceOverviewProps, ICDBalanceOverviewUIProps } from './types';
import BannerToast from '../../../../atoms/BannerToast';
import {
    transformCDBalanceOverviewForUI,
    transformEndorsementOverviewForUI
} from '../../../../../utils/constants/Endorsement/transforms';
import {
    isPreProcessState,
    toDisableCTAForEndorsementStates
} from '../../../../../utils/constants/Endorsement/PossibleValue';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import DeleteEndorsement from '../../../../containers/DeleteEndorsementModal/DeleteEndorsement';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { generateDeleteEndorsementProps, isCdBalanceBannerVisible, isDeleteButtonVisible } from './EndorsementService';
import { memberDataExcelDownload } from '../../../../../utils/constants/Endorsement/membersToExcelService';
import { insurerFormatList, showInsurerFormatListForProvidedPolicy } from './constant';


const EndorsementDetails: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [searchParams] = useSearchParams();
    const endorsementId = searchParams.get('id');
    const [isLoading, toggleLoading] = React.useState(true);
    const [isInsurerMemberDownloadVisible, setIsInsurerMemberDownloadVisible] = React.useState(false);
    const [isMemberDownloadLoading, toggleMemberDownloadLoading] = React.useState(false);
    const [endorsementDetails, setEndorsementDetails] = React.useState<IEndorsement | null>(null);
    const [shouldShowEstimatedBalance, setShouldShowEstimatedBalance] = React.useState(true);
    const [cdBalanceOverview, setCdBalanceOverview] = React.useState<ICDBalanceOverviewProps | null>(null);
    const cdBalanceOverviewData: ICDBalanceOverviewUIProps = transformCDBalanceOverviewForUI(cdBalanceOverview);
    const [isDeleteModalVisible, setDeleteModalVisible] = React.useState(false);
    const user = useSelector((state: ReduxState) => state.user?.userData?.data);

    const isPreProcessStates: boolean = endorsementDetails?.status
        ? isPreProcessState(endorsementDetails.status)
        : false;

    const hasDeleteAccess: boolean = (user?.roles as string[])?.includes('loopOwner');
    const showInsurerFormat = (
        insurerName: string | undefined,
        policyType: string | undefined
    ): boolean => {
        let result = false;
        const policyList = insurerName && showInsurerFormatListForProvidedPolicy[insurerName] || [];
        if (policyType && policyList.includes(policyType)) {
            result = true;
        }
        return result;
    };
    const toDisableCTAForEndorsementState: boolean = endorsementDetails?.status
        ? toDisableCTAForEndorsementStates(endorsementDetails.status)
        : true;

    useEffect(() => {
        fetchEndorsementDetails();
        fetchCdBalanceOverviewDetails();
    }, []);

    useEffect(() => {
        if (endorsementDetails) {
            const { status } = endorsementDetails;
            setShouldShowEstimatedBalance(status === 'IN_PROGRESS' || status === 'DRAFT');
        }
        if (
            showInsurerFormat(endorsementDetails?.insurerId, endorsementDetails?.policyType) &&
            endorsementDetails?.insurerId &&
            insurerFormatList.includes(endorsementDetails?.insurerId) &&
            endorsementDetails.status !== 'DRAFT'
        ) {
            setIsInsurerMemberDownloadVisible(true);
        }
    }, [endorsementDetails]);

    const fetchEndorsementDetails = async () => {
        if (!endorsementId) return;
        const [error, endoDetails] = await parseResponse(fetchEndorsementByID(endorsementId));
        toggleLoading(false);
        if (error) {
            showApiFailureToast(toast);
            return;
        }
        setEndorsementDetails(endoDetails?.data);
    };

    const fetchCdBalanceOverviewDetails = async () => {
        if (!endorsementId) return;
        const [error, cdDetails] = await parseResponse(fetchCdBalanceOverviewById(endorsementId));
        if (error) {
            showApiFailureToast(toast, error.toString());
            return;
        }
        setCdBalanceOverview(cdDetails?.data);
    };
    const endorsementOverviewProps = transformEndorsementOverviewForUI(endorsementDetails);

    const statusProps = {
        endorsement: endorsementDetails,
        toDisableCTAForEndorsementState,
        currentStatus: endorsementDetails?.status as IStatus,
        nextStatus: endorsementDetails?.nextEndorsmentStatus as IStatus
    };

    const updateEndorsementStatus = (responseData: IEndorsementStatusUpdateResponse) => {
        const updatedEndoDetails = {
            ...endorsementDetails,
            status: responseData.status,
            nextEndorsmentStatus: responseData.nextEndorsmentStatus
        };
        setEndorsementDetails(updatedEndoDetails as IEndorsement);
        if (responseData.status == 'IN_PROGRESS') {
            toggleLoading(true);
            fetchCdBalanceOverviewDetails();
            fetchEndorsementDetails();
        }
        fetchCdBalanceOverviewDetails();
    };
    const updateEndorsementNotes = (notes: NoteEntity[]) => {
        const updatedEndoDetails = {
            ...endorsementDetails,
            notes
        };
        setEndorsementDetails(updatedEndoDetails as IEndorsement);
    };

    const updateEndorsementMembers = (memberData: MemberDataEntity[]) => {
        const updatedEndoDetails = {
            ...endorsementDetails,
            memberData
        };
        setEndorsementDetails(updatedEndoDetails as IEndorsement);
    };

    const navigateToEndorsementList = () => {
        navigate(-1);
    };

    const downloadMemberData = () => {
        if (!endorsementDetails?.memberData) return;
        memberDataExcelDownload(
            endorsementDetails?.memberData,
            `${endorsementDetails?.companyId}-endorsement-member-data`
        );
    };

    const downloadInsurerMemberData = async () => {
        if (!endorsementDetails?.memberData) return;
        toggleMemberDownloadLoading(true);
        const blob = await downloadMemberdata(endorsementDetails.insurerId, endorsementDetails.id);
        let fileName = `InsurerMemberData_${new Date().toISOString()}`;
        if (blob.type === 'application/zip') {
            fileName = `${fileName}.zip`;
        } else {
            fileName = `${fileName}.xlsx`;
        }

        await downloadBlobAsFile(blob as unknown as Blob, fileName);
        toggleMemberDownloadLoading(false);
    };

    const notesProps = {
        endorsementId,
        notes: endorsementDetails?.notes || [],
        updateEndorsementNotes
    };

    const deleteEndorsementProps = generateDeleteEndorsementProps(
        endorsementDetails,
        isDeleteModalVisible,
        setDeleteModalVisible,
        endorsementOverviewProps,
        endorsementId
    );

    const showDeleteButton = isDeleteButtonVisible(hasDeleteAccess, endorsementDetails);
    const showCdBalanceBanner = isCdBalanceBannerVisible(cdBalanceOverview, cdBalanceOverviewData, isPreProcessStates);
    return (
        <StyledContainer>
            <StyledHeader>
                <StyledTitleWrapper>
                    <img src={BackIcon} onClick={navigateToEndorsementList} alt={'back-icon'} />
                    <h4>{endorsementDetails?.companyName}</h4>
                </StyledTitleWrapper>
                {showDeleteButton && (
                    <StyledButtonWrapper>
                        <LoopButton variant="text" onClick={() => setDeleteModalVisible(true)} iconSrc={DeleteIcon}>
                            <Typography variant="small" color="emerald" weight="medium">
                                Delete endorsement
                            </Typography>
                        </LoopButton>
                    </StyledButtonWrapper>
                )}

                {/* <StyledFilterWrapper>*/}
                {/*    {Object.entries(selectFilters).map(([key, value], i) => {*/}
                {/*        const { label = '', options = [] } = getFilterOptions(key as iEndorsementFilter);*/}
                {/*        return (*/}
                {/*            <StyledMultiSelectWrapper key={key}>*/}
                {/*                <StyledFilterLabel>{label}</StyledFilterLabel>*/}
                {/*                <Select*/}
                {/*                    styles={singleSelectStyles}*/}
                {/*                    autoFocus={i === 0}*/}
                {/*                    onChange={(val) => !!val &&
                setSelectFilters({ ...selectFilters, [key]: val })}*/}
                {/*                    options={key === 'status' ? options.filter((o) =>*/}
                {/*                        !o.value || status.includes(o.value as IStatus)) : options}*/}
                {/*                    placeholder="All"*/}
                {/*                    tabSelectsValue={false}*/}
                {/*                    value={value}*/}
                {/*                    components={{*/}
                {/*                        IndicatorSeparator: () => null*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*            </StyledMultiSelectWrapper>*/}
                {/*        );*/}
                {/*    })}*/}

                {/* </StyledFilterWrapper>*/}
            </StyledHeader>

            {showCdBalanceBanner && (
                <BannerToast type="ERROR">
                    CD Balance for this account is insufficient. Please ask the HR to top up in order to proceed forward
                    with this endorsement.
                </BannerToast>
            )}
            <EndorsementDetailsWrapper>
                {isLoading ? (
                    <StyledTableLoaderContainer>
                        <Loader />
                    </StyledTableLoaderContainer>
                ) : endorsementDetails ? (
                    <>
                        <EndorsementStateComponent
                            {...statusProps}
                            isCDBalanceSufficient={!!cdBalanceOverview && !!cdBalanceOverviewData?.balanceSufficient}
                            cdBalanceOverview={cdBalanceOverview}
                            updateEndorsementStatus={updateEndorsementStatus}
                            policyId={endorsementDetails.policyId}
                        />
                        <EndorsementOverview
                            {...endorsementOverviewProps}
                            fetchEndorsementDetails={fetchEndorsementDetails}
                        />
                        <CDBalanceOverview
                            {...cdBalanceOverview}
                            isPreProcessStates={isPreProcessStates}
                            shouldShowEstimatedBalance={shouldShowEstimatedBalance}
                        />
                        <NotesContainer {...notesProps} />
                        <DeleteEndorsement {...deleteEndorsementProps()} />
                        <EndorsementDataExpandable
                            policyId={endorsementDetails.policyId}
                            downloadMemberData={downloadMemberData}
                            downloadInsurerMemberData={downloadInsurerMemberData}
                            isInsurerMemberDownloadVisible={isInsurerMemberDownloadVisible}
                            isMemberDownloadLoading={isMemberDownloadLoading}
                            memberData={endorsementDetails?.memberData}
                            endorsementId={endorsementDetails.id}
                            status={endorsementDetails.status}
                            updateEndorsementMembers={updateEndorsementMembers}
                        />
                    </>
                ) : null}
            </EndorsementDetailsWrapper>
        </StyledContainer>
    );
};

export default EndorsementDetails;
