const isFeatureEnabled = (feature: Feature): boolean => {
    const env = process.env as Record<string, string>;
    if (Object.keys(env).includes(feature.toString())) {
        return JSON.parse(env[feature.toString()]) as boolean;
    }
    return false;
};
enum Feature {
    PREMUUM_RATER_FEATURE = 'REACT_APP_FEATURE_PREMIUM_RATER',
    NEW_BULK_REGISTER_FEATURE = 'REACT_APP_FEATURE_NEW_BULK_REGISTER',
    MERGE_MEMBERS_FEATURE = 'REACT_APP_FEATURE_MERGE_MEMBERS',
    EDIT_EMPLOYEEID_FEATURE = 'REACT_APP_FEATURE_EDIT_EMPLOYEEID',
    DELETE_MEMBERS_FEATURE = 'REACT_APP_FEATURE_DELETE_MEMBERS',
    SEARCH_MEMBER_BY_EMP_ID_FEATURE = 'REACT_APP_SEARCH_MEMBER_BY_EMP_ID_FEATURE',
    CDACCOUNT_FEATURE = 'REACT_APP_FEATURE_CDACCOUNT',
    PREVALIDATION_FEATURE = 'REACT_APP_FEATURE_PREVALIDATION',
    HRADDITION_FEATURE = 'REACT_APP_FEATURE_HRADDITION',
    ACCESS_MANAGEMENT = 'REACT_APP_FEATURE_ACCESS_MAMANEMENT',
    SKF_ONBOARDING_FEATURE = 'REACT_APP_FEATURE_SKF_ONBOARDING',
    PREVALIDATION_AND_HR_ADDITION_FEATURE = 'REACT_APP_FEATURE_PREVALIDATION_HRADDITION',
    CHANGE_REQUEST_FEATURE = 'REACT_APP_FEATURE_CHANGE_REQUEST',
    CD_LEDGER_DOWNLOAD = 'REACT_APP_CD_LEDGER_DOWNLOAD',
    ENROLMENT_REPORT = 'REACT_APP_FEATURE_ENROLMENT_REPORT',
    ENROLMENT_CONFIG = 'REACT_APP_FEATURE_ENROLMENT_CONFIG',
    ENDO_TOOLING = 'REACT_APP_FEATURE_ENDO_TOOLING',
    ISSUANCE_FLOW = 'REACT_APP_FEATURE_ISSUANCE_FLOW',
    DEPRECATE_DB_CALLS = 'REACT_APP_DEPRECATE_DB_CALLS',
    VALIDATIONS_SUMMARY = 'REACT_APP_FEATURE_VALIDATIONS_SUMMARY',
    INSURER_MEMBER_DATA_FORMAT = 'REACT_APP_FEATURE_INSURER_MEMBER_DATA_FORMAT',
    VALIDATOR = 'REACT_APP_VALIDATOR',
    RECALCULATE_PREMIUMS_TRIGGER = 'REACT_APP_RECALCULATE_PREMIUMS_TRIGGER',
    IAM_BE_API = 'REACT_APP_ALLOW_IAM_BE_API',
    CD_ACCOUNT_DELETION = 'REACT_APP_CD_ACCOUNT_DELETION',
    FRESHDESK_VALIDATOR_INTEGRATION = 'REACT_APP_FRESHDESK_VALIDATOR_INTEGRATION',
    DATA_DISCREPANCY_FIX = 'REACT_APP_DATA_DISCREPANCY_FIX'
}

export const isCdAccountEnabled = isFeatureEnabled(Feature.CDACCOUNT_FEATURE);
export const isPremiumRaterEnabled = isFeatureEnabled(Feature.PREMUUM_RATER_FEATURE);
export const isNewBulkRegisterEnabled = isFeatureEnabled(Feature.NEW_BULK_REGISTER_FEATURE);
export const isMergeMembersEnabled = isFeatureEnabled(Feature.MERGE_MEMBERS_FEATURE);
export const isEmployeeIdEditEnabled = isFeatureEnabled(Feature.EDIT_EMPLOYEEID_FEATURE);
export const isDeleteMembersEnabled = isFeatureEnabled(Feature.DELETE_MEMBERS_FEATURE);
export const isSearchMemberByEmpIdFeatureEnabled = isFeatureEnabled(Feature.SEARCH_MEMBER_BY_EMP_ID_FEATURE);
export const isHRAdditionEnabled = isFeatureEnabled(Feature.HRADDITION_FEATURE);
export const isPreValidationEnabled = isFeatureEnabled(Feature.PREVALIDATION_FEATURE);
export const isAccessManagementEnabled = isFeatureEnabled(Feature.ACCESS_MANAGEMENT);
export const isSkfOnboardingEnabled = isFeatureEnabled(Feature.SKF_ONBOARDING_FEATURE);
export const isPreValidationAndHRAdditionEnabled = isFeatureEnabled(Feature.PREVALIDATION_AND_HR_ADDITION_FEATURE);
export const isChangeRequestEnabled = isFeatureEnabled(Feature.CHANGE_REQUEST_FEATURE);
export const isCdLedgerDownloadEnabled = isFeatureEnabled(Feature.CD_LEDGER_DOWNLOAD);
export const isEnrolmentReportEnabled = isFeatureEnabled(Feature.ENROLMENT_REPORT);
export const isEnrolmentConfigEnabled = isFeatureEnabled(Feature.ENROLMENT_CONFIG);
export const isEndoToolingEnabled = isFeatureEnabled(Feature.ENDO_TOOLING);
export const isIssuanceFlowEnabled = isFeatureEnabled(Feature.ISSUANCE_FLOW);
export const isDeprecateDBCallsEnabled = isFeatureEnabled(Feature.DEPRECATE_DB_CALLS);
export const isValidationSummaryEnabled = isFeatureEnabled(Feature.VALIDATIONS_SUMMARY);
export const isInsurerMemberDataFormatEnabled = isFeatureEnabled(Feature.INSURER_MEMBER_DATA_FORMAT);
export const isValidatorEnabled = isFeatureEnabled(Feature.VALIDATOR);
export const isIamAPIEnabled = isFeatureEnabled(Feature.IAM_BE_API);
export const isRecalculatePremiumsTriggerEnabled = isFeatureEnabled(Feature.RECALCULATE_PREMIUMS_TRIGGER);
export const isCdAccountDeletionEnabled = isFeatureEnabled(Feature.CD_ACCOUNT_DELETION);
export const isFreshdeskValidatorIntegrated = isFeatureEnabled(Feature.FRESHDESK_VALIDATOR_INTEGRATION);
export const isDataDiscrepancyFixEnabled = isFeatureEnabled(Feature.DATA_DISCREPANCY_FIX);
