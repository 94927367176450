import React, { useEffect, useMemo, useState } from 'react';
import { IMidtermVerifyModal } from './types';
import { Dialog, Form, Loader, LoopButton, Modal, Toast, Typography } from '@loophealth/loop-ui-web-library';
import WithThemeProvider from '../../../../../theme/WithThemeProvider';
import {
    StyledApprovalContainer,
    StyledApprovalIconContainer,
    StyledApprovalTextContainer,
    StyledDnfCheckButton,
    StyledDnfCheckCTA,
    StyledDnfCheckContainer,
    StyledDnfCheckTextContainer,
    StyledDocumentIcon,
    StyledLastStepContainer,
    StyledMarriageCertificate,
    StyledMarriageCertificateContainer,
    StyledMidtermModalBody,
    StyledMidtermModalFooter,
    StyledMidtermModalHeader,
    StyledMidtermModalWrapper,
    StyledOpenIcon,
    StyledRejectModalContent
} from './styles';
import { OpenInNewIcon, PasteIcon, TrashIconCircle } from '../../../../../assets/img';
import { getSignedBlob } from '../../../../../utils/constants/Endorsement';
import { fileSizeToString } from '../../../../containers/ValidationSummaryDetails/utils';
import { modifyCSDForMidterms, updateDocumentVerificationStatus } from '../../../../../adapters';
import { formatDateString } from '../../../../../utils/dateFormatService';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import useSegment from '../../../../../utils/segment/hooks/useSegment';
import { useLocation } from 'react-router-dom';
import { extractPathName, trackClickEvent } from '../../../../../utils/segment/utils';

const MidtermVerifyModal: React.FC<IMidtermVerifyModal> = ({
    midtermMember,
    setMidtermMember,
    policyId,
    endorsementId,
    updateMemberDetails
}) => {
    const trackClick = useSegment('click');
    const location = useLocation();
    const toast = Toast.useToast();
    const user = useSelector((state: ReduxState) => state.user?.userData?.data);
    const isSpouse = midtermMember?.relationship.toLowerCase() === 'spouse';
    const [isVisible, setIsVisible] = useState(false);
    const [isRejectVisible, setIsRejectVisible] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isDNF, setIsDnf] = useState<boolean | null>(null);
    const [coverageDate, setCoverageDate] = useState<Date | null>(null);

    const [midtermFile, setMidtermFile] = useState<Blob | null>(null);
    const [isLoading, toggleLoading] = useState(false);
    const midtermFileURL = useMemo(() => {
        return midtermFile ? URL.createObjectURL(midtermFile) : '';
    }, [midtermFile]);

    useEffect(() => {
        if (midtermMember) {
            setIsVisible(true);
            if (isSpouse) {
                setIsVerified(midtermMember.isMidTermDocumentVerified);
                toggleLoading(true);
                getSignedBlob(midtermMember.midTermDocumentURL).then((file) => {
                    setMidtermFile(file);
                    toggleLoading(false);
                });
            } else {
                setIsVerified(true);
            }
        } else {
            setIsVisible(false);
        }
        if (midtermMember.policyStartDate) {
            setCoverageDate(new Date(midtermMember.policyStartDate));
        }
    }, [midtermMember]);

    const handleSubmit = async () => {
        const formattedDate = formatDateString(midtermMember.policyStartDate);
        let updatedMemberData = { ...midtermMember };
        toggleLoading(true);
        if (isDNF && coverageDate && formatDateString(coverageDate.toDateString()) !== formattedDate) {
            if (isSpouse) {
                trackClick(
                    trackClickEvent(
                        'SubmitDetails_YesDNF_Accept_gen',
                        location?.pathname,
                        extractPathName(location?.pathname)
                    )
                );
            } else {
                trackClick(
                    trackClickEvent(
                        'ChildSubmitDetails_YesDNF_Accept_gen',
                        location?.pathname,
                        extractPathName(location?.pathname)
                    )
                );
            }

            try {
                const response = await modifyCSDForMidterms(
                    midtermMember.userId,
                    policyId,
                    formattedDate,
                    user?.userId
                );
                const data = response.data.response;
                if (data.invalidMembers.length || !data.validMembers.length) {
                    throw new Error('Unable to edit Coverage Start Date');
                }
                updatedMemberData = { ...updatedMemberData, ...data.validMembers[0] };
            } catch (e) {
                toast?.error((e as Error).message);
                toggleLoading(false);
                return;
            }
        }
        try {
            if (isSpouse) {
                trackClick(
                    trackClickEvent(
                        'SubmitDetails_NoDNF_Accept_gen',
                        location?.pathname,
                        extractPathName(location?.pathname)
                    )
                );
            } else {
                trackClick(
                    trackClickEvent(
                        'ChildSubmitDetails_NoDNF_Accept_gen',
                        location?.pathname,
                        extractPathName(location?.pathname)
                    )
                );
            }
            await updateDocumentVerificationStatus(midtermMember.userId, endorsementId, true);
            toast?.success(`“${midtermMember.firstName}” has been successfully verified.`);
            updatedMemberData.isMidTermDocumentVerified = true;
        } catch (e) {
            toast?.error((e as Error).message);
            toggleLoading(false);
            return;
        }
        updateMemberDetails(updatedMemberData);
        setMidtermMember(undefined);
        toggleLoading(false);
    };

    const handleReject = async () => {
        toggleLoading(true);
        trackClick(trackClickEvent('YesReject_gen', location?.pathname, extractPathName(location?.pathname)));
        try {
            await updateDocumentVerificationStatus(midtermMember.userId, endorsementId, false);
            toast?.success(`“${midtermMember.firstName}” has been successfully deleted from the endorsement.`);
            updateMemberDetails(midtermMember, true);
        } catch (e) {
            toast?.error((e as Error).message);
            return;
        } finally {
            toggleLoading(false);
        }
        setMidtermMember(undefined);
    };

    const openInNewWindow = () => {
        trackClick(
            trackClickEvent('OpenNewWindow_Verify_gen', location?.pathname, extractPathName(location?.pathname))
        );
        window.open(midtermFileURL, '_blank');
    };

    const handleSetCoverageDate = () => {
        setCoverageDate;
        if (isSpouse) {
            trackClick(trackClickEvent('Date_DNFFound_gen', location?.pathname, extractPathName(location?.pathname)));
        } else {
            trackClick(
                trackClickEvent('Date_ChildDNFFound_gen', location?.pathname, extractPathName(location?.pathname))
            );
        }
    };
    const cancelMidtermVerificationModal = () => {
        if (isLoading) return;
        setMidtermMember(undefined);
    };

    const showRejectModal = () => {
        trackClick(trackClickEvent('Reject_Verify_gen', location?.pathname, extractPathName(location?.pathname)));
        setIsVisible(false);
        setIsRejectVisible(true);
    };

    const cancelRejectModal = () => {
        if (isLoading) return;
        setIsRejectVisible(false);
        setIsVisible(true);
        trackClick(trackClickEvent('GoBack_Cross_gen', location?.pathname, extractPathName(location?.pathname)));
    };

    const handleUndoApproveClick = () => {
        setIsVerified(false);
        trackClick(trackClickEvent('UndoApprove_gen', location?.pathname, extractPathName(location?.pathname)));
    };
    const handleAcceptVerificationClick = () => {
        setIsVerified(true);
        trackClick(trackClickEvent('Accept_Verift_gen', location?.pathname, extractPathName(location?.pathname)));
    };
    const handleConfirmYesDNFState = () => {
        setIsDnf(true);
        if (isSpouse) {
            trackClick(trackClickEvent('Yes_DNFFound_gen', location?.pathname, extractPathName(location?.pathname)));
        } else {
            trackClick(
                trackClickEvent('Yes_ChildDNFFound_gen', location?.pathname, extractPathName(location?.pathname))
            );
        }
    };
    const handleConfirmNoDNFState = () => {
        setIsDnf(false);
        if (isSpouse) {
            trackClick(trackClickEvent('No_DNFNotFound_gen', location?.pathname, extractPathName(location?.pathname)));
        } else {
            trackClick(
                trackClickEvent('No_ChildDNFFound_gen', location?.pathname, extractPathName(location?.pathname))
            );
        }
    };
    const modalTitle = useMemo(() => {
        return isSpouse
            ? isVerified
                ? 'Just one last step...'
                : `${midtermMember.firstName}’s Marriage Certificate`
            : `${midtermMember.firstName}’s DNF Verification`;
    }, [isVerified]);

    const isSubmitEnabled = useMemo(() => {
        return (isDNF === true && coverageDate) || isDNF === false;
    }, [isDNF, coverageDate]);

    return (
        <>
            <Modal
                isVisible={isVisible}
                setIsVisible={cancelMidtermVerificationModal}
                overflow="visible"
                disablePadding
            >
                <WithThemeProvider>
                    <StyledMidtermModalWrapper>
                        <StyledMidtermModalHeader>
                            <Typography variant="medium" weight="medium">
                                {modalTitle}
                            </Typography>
                        </StyledMidtermModalHeader>
                        <StyledMidtermModalBody>
                            {isSpouse && !isVerified ? (
                                <StyledMarriageCertificateContainer>
                                    {isLoading ? (
                                        <Loader />
                                    ) : midtermFileURL ? (
                                        <>
                                            <StyledOpenIcon src={OpenInNewIcon} onClick={openInNewWindow} />
                                            <StyledMarriageCertificate src={midtermFileURL} />
                                        </>
                                    ) : (
                                        <Typography variant="small">
                                            Some error occured while loading document
                                        </Typography>
                                    )}
                                </StyledMarriageCertificateContainer>
                            ) : (
                                <StyledLastStepContainer>
                                    {isSpouse && (
                                        <StyledApprovalContainer>
                                            <StyledApprovalIconContainer>
                                                <StyledDocumentIcon>
                                                    <img src={PasteIcon} />
                                                </StyledDocumentIcon>
                                                <StyledApprovalTextContainer>
                                                    <Typography variant="medium" weight="medium">
                                                        {midtermMember.midTermDocumentURL}
                                                    </Typography>
                                                    <Typography variant="extraSmall" color="secondary">
                                                        {fileSizeToString(midtermFile?.size || 0)}
                                                    </Typography>
                                                </StyledApprovalTextContainer>
                                            </StyledApprovalIconContainer>
                                            <LoopButton
                                                size="small"
                                                variant={isLoading ? 'disabled' : 'outline'}
                                                onClick={handleUndoApproveClick}
                                            >
                                                Undo Approve
                                            </LoopButton>
                                        </StyledApprovalContainer>
                                    )}
                                    <StyledDnfCheckContainer>
                                        <StyledDnfCheckTextContainer>
                                            <Typography variant="small" weight="medium">
                                                Please Confirm if DNF is Found
                                            </Typography>
                                            <StyledDnfCheckCTA>
                                                <StyledDnfCheckButton
                                                    $isSelected={isDNF === true}
                                                    onClick={handleConfirmYesDNFState}
                                                >
                                                    <Typography
                                                        variant="extraSmall"
                                                        weight="medium"
                                                        color={isDNF === true ? 'tertiary' : 'primary'}
                                                    >
                                                        Yes
                                                    </Typography>
                                                </StyledDnfCheckButton>
                                                <StyledDnfCheckButton
                                                    $isSelected={isDNF === false}
                                                    onClick={handleConfirmNoDNFState}
                                                >
                                                    <Typography
                                                        variant="extraSmall"
                                                        weight="medium"
                                                        color={isDNF === false ? 'tertiary' : 'primary'}
                                                    >
                                                        No
                                                    </Typography>
                                                </StyledDnfCheckButton>
                                            </StyledDnfCheckCTA>
                                        </StyledDnfCheckTextContainer>
                                        {isDNF && (
                                            <Form.DatePicker
                                                value={coverageDate}
                                                onChange={handleSetCoverageDate}
                                                placeholder="Enter New Coverage Date"
                                                displayFormat="DD-MMM-YYYY"
                                            />
                                        )}
                                    </StyledDnfCheckContainer>
                                </StyledLastStepContainer>
                            )}
                        </StyledMidtermModalBody>
                        <StyledMidtermModalFooter>
                            {isVerified ? (
                                <>
                                    <LoopButton
                                        variant={isLoading ? 'disabled' : 'outline'}
                                        textColor="error"
                                        onClick={() => setMidtermMember(undefined)}
                                    >
                                        Cancel
                                    </LoopButton>
                                    <LoopButton
                                        variant={isSubmitEnabled ? 'filled' : 'disabled'}
                                        onClick={handleSubmit}
                                        isLoading={isLoading}
                                    >
                                        Submit Details
                                    </LoopButton>
                                </>
                            ) : (
                                <>
                                    <LoopButton variant="outline" textColor="error" onClick={showRejectModal}>
                                        Reject
                                    </LoopButton>
                                    <LoopButton
                                        variant={midtermFileURL ? 'filled' : 'disabled'}
                                        onClick={handleAcceptVerificationClick}
                                        isLoading={isLoading}
                                    >
                                        Accept
                                    </LoopButton>
                                </>
                            )}
                        </StyledMidtermModalFooter>
                    </StyledMidtermModalWrapper>
                </WithThemeProvider>
            </Modal>
            <Modal isVisible={isRejectVisible} setIsVisible={cancelRejectModal}>
                <Dialog
                    variant="vertical"
                    icon={TrashIconCircle}
                    description="If you reject this document, then this user will be deleted from endorsement."
                    title="Are you sure you want to Reject this document?"
                    primaryButtonText="Yes, Reject"
                    buttonVariant="error"
                    onPrimaryButtonClicked={handleReject}
                    onSecondaryButtonClicked={cancelRejectModal}
                    secondaryButtonText="Go Back"
                    isLoading={isLoading}
                    content={
                        <WithThemeProvider>
                            <StyledRejectModalContent>
                                <Typography variant="small" color="secondary">
                                    HR will be notified to send us correct document
                                </Typography>
                            </StyledRejectModalContent>
                        </WithThemeProvider>
                    }
                />
            </Modal>
        </>
    );
};

export default MidtermVerifyModal;
