import React from 'react';
import {
    StyledChipContainer,
    StyledContainer,
    StyledSpacingContainer,
    StyledSubTitle,
    StyledTitleContainer,
    StyledTopContainer
} from './styles';
import SVG from 'react-inlinesvg';
import { BusyLady, ErrorQuestionMark, LogoutIcon } from '../../../../../assets/img';
import { Chip, Dialog, Form, LoopTable, Modal, Typography } from '@loophealth/loop-ui-web-library';
import CustomBottomNavigator from '../../../../containers/CustomBottomNavigator';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetState,
    setDnfTableData,
    setMembersToUpload,
    setRejectedEntries
} from '../../../../../redux/slices/ValidatorSlice';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { ReduxState } from '../../../../../redux';
import {
    formatTableData,
    generateValidateMidTermsRequest,
    getMinCoverageDate,
    getMinDate,
    getUpdatedCoverageDates,
    getUpdatedRejectedEntries,
    isCoverageDateRequired,
    isMarriageDateRequired,
    isMarriagedateMissing,
    isProceedActive
} from './utils';
import { ICoverageDate, IFoundDnf, IMarriageDate, ITableData } from './types';
import { showApiFailureToast } from '../../../../../utils/common';
import { useToast } from '../../../../hooks/useToast';
import { validateMidterms } from '../../../../../adapters';

const DnfChecklist: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const uploadedData = useSelector((state: ReduxState) => state.validatorSlice.membersToUpload);
    const identifiedMidterms = useSelector((state: ReduxState) => state.validatorSlice.identifiedMidTerms);
    const identifiedKyc = useSelector((state: ReduxState) => state.validatorSlice.identifiedKyc);
    const headers = ['S.No.', 'Employee ID', 'Name', 'Relationship', 'Marriage Date', 'Found a DNF', 'Coverage Date'];
    const [tableData, setTableData] = React.useState<ITableData[]>([]);
    const [missingDOM, setMissingDOM] = React.useState<boolean>(false);
    const [cancel, setCancel] = React.useState<boolean>(false);
    const canGoBack = !!(identifiedKyc?.EDIT.length || identifiedKyc?.OFFBOARD.length);
    const rejectedEntries = useSelector((state: ReduxState) => state.validatorSlice.rejectedEntries);
    const dnfTableData = useSelector((state: ReduxState) => state.validatorSlice.dnfTableData);

    // Navigation on refresh/on no data
    React.useEffect(() => {
        if (
            identifiedMidterms?.acceptedMidTermAdditions?.add?.length === 0 &&
            identifiedMidterms?.acceptedMidTermAdditions?.edit?.length === 0
        ) {
            navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.VALIDATION_SUMMARY.route);
        }

        if (!uploadedData?.add.length && !uploadedData?.edit.length && !uploadedData?.offboard.length) {
            navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.VALIDATOR.route);
        }
    }, []);

    React.useEffect(() => {
        if (dnfTableData?.length) {
            const currentTable = [...dnfTableData];
            setTableData(currentTable);
        } else if (
            identifiedMidterms?.acceptedMidTermAdditions.add ||
            identifiedMidterms?.acceptedMidTermAdditions.edit
        ) {
            const displayMidTerms = identifiedMidterms.acceptedMidTermAdditions.add.concat(
                identifiedMidterms.acceptedMidTermAdditions.edit
            );
            const tableData = uploadedData?.add.concat(uploadedData.edit) || [];
            const formatedData = formatTableData(tableData, displayMidTerms);
            setTableData(formatedData);
        }
    }, [identifiedMidterms]);

    const handleChipClicks = (index: number, value: boolean) => {
        const updatedData = [...tableData];
        updatedData[index] = {
            ...updatedData[index],
            'Found a DNF': {
                ...updatedData[index]['Found a DNF'],
                isSelected: value
            },
            'Coverage Date': {
                ...updatedData[index]['Coverage Date'],
                coverageDate: value ? updatedData[index]['Coverage Date'].coverageDate : undefined
            }
        };
        setTableData(updatedData);
    };

    const handleDateChange = (index: number, value: Date, field: string, fieldName: string) => {
        const updatedData: ITableData[] = [...tableData];
        updatedData[index][field] = { [fieldName]: value, index };
        const currentRecord = updatedData[index];
        const dataToUpload = getUpdatedCoverageDates(currentRecord, uploadedData, identifiedMidterms);
        dispatch(setMembersToUpload(dataToUpload));
        setTableData(updatedData);
    };

    const handleNext = () => {
        if (isMarriagedateMissing(tableData)) setMissingDOM(true);
        else navigateNext();
    };

    const navigateNext = async () => {
        const addEditUsers = generateValidateMidTermsRequest(
            identifiedMidterms?.acceptedMidTermAdditions,
            uploadedData,
            tableData
        );
        try {
            const response = await validateMidterms(addEditUsers);
            if (response.data) {
                const updatedRejectedEntries = getUpdatedRejectedEntries(
                    identifiedMidterms?.rejectedMidTermAdditions,
                    response.data.rejectedMidTermAdditions,
                    uploadedData,
                    rejectedEntries
                );

                dispatch(setRejectedEntries(updatedRejectedEntries));
                navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.VALIDATION_SUMMARY.route);
            }
        } catch (error) {
            showApiFailureToast(toast, (error as Error).toString());
        }
    };
    const handleBack = () => {
        if (canGoBack) {
            dispatch(setDnfTableData(tableData));
            navigate(-1);
        } else {
            setCancel(true);
        }
    };

    const navigateToValidator = () => {
        dispatch(resetState());
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.VALIDATOR.route);
    };

    return (
        <StyledContainer>
            <StyledSpacingContainer>
                <StyledTopContainer>
                    <SVG src={BusyLady} />
                    <StyledTitleContainer>
                        <Typography variant="title3" weight="medium">
                            One last Step...
                        </Typography>

                        <StyledSubTitle>
                            <Typography variant="medium" weight="regular">
                                You&apos;ll have to mark DNF for mid term addition requests
                            </Typography>
                        </StyledSubTitle>
                    </StyledTitleContainer>
                </StyledTopContainer>

                <LoopTable
                    headers={headers}
                    data={tableData}
                    processor={{
                        [headers[4]]: {
                            cell: (value) => (
                                <>
                                    {isMarriageDateRequired((value as Record<string, number>).index, tableData) ? (
                                        <Form.DatePicker
                                            value={(value as IMarriageDate).marriageDate as Date}
                                            onChange={(date) =>
                                                handleDateChange(
                                                    (value as IMarriageDate).index,
                                                    date as Date,
                                                    'Marriage Date',
                                                    'marriageDate'
                                                )
                                            }
                                            minDate={getMinDate(45)}
                                            maxDate={new Date()}
                                            placeholder="Select a Date"
                                        />
                                    ) : (
                                        <div>-</div>
                                    )}
                                </>
                            )
                        },
                        [headers[5]]: {
                            cell: (value) => (
                                <StyledChipContainer>
                                    <Chip
                                        key={'Yes'}
                                        label={'Yes'}
                                        isActive={(value as IFoundDnf).isSelected === true}
                                        onClick={() => handleChipClicks((value as Record<string, number>).index, true)}
                                    />
                                    <Chip
                                        key={'No'}
                                        label={'No'}
                                        isActive={(value as IFoundDnf).isSelected === false}
                                        onClick={() => handleChipClicks((value as Record<string, number>).index, false)}
                                        variant="error"
                                    />
                                </StyledChipContainer>
                            )
                        },
                        [headers[6]]: {
                            cell: (value) => (
                                <div>
                                    {isCoverageDateRequired((value as Record<string, number>).index, tableData) ? (
                                        <Form.DatePicker
                                            value={(value as ICoverageDate).coverageDate as Date}
                                            onChange={(date) =>
                                                handleDateChange(
                                                    (value as ICoverageDate).index,
                                                    date as Date,
                                                    'Coverage Date',
                                                    'coverageDate'
                                                )
                                            }
                                            minDate={getMinCoverageDate(tableData, (value as ICoverageDate).index)}
                                            maxDate={new Date()}
                                            placeholder="Select a Date"
                                        />
                                    ) : (
                                        <div>-</div>
                                    )}
                                </div>
                            )
                        }
                    }}
                />
            </StyledSpacingContainer>

            <CustomBottomNavigator
                leftButtonClick={handleBack}
                rightButtonClick={handleNext}
                activateNext={isProceedActive(tableData, identifiedMidterms)}
                rightButtonLabel="Save & Proceed"
                leftButtonLabel={ canGoBack ? 'Go back': 'Cancel'}
            />

            <Modal isVisible={missingDOM} setIsVisible={setMissingDOM}>
                <Dialog
                    variant="vertical"
                    icon={ErrorQuestionMark}
                    description="Are you sure you want to proceed further?
                    Because endorsements won’t be processed for these entries if marriage date is not provided."
                    title={'Marriage date is missing for some entries!'}
                    primaryButtonText="Yes, Proceed"
                    onPrimaryButtonClicked={() => navigateNext()}
                    onSecondaryButtonClicked={() => setMissingDOM(false)}
                    secondaryButtonText="Cancel"
                    buttonVariant="error"
                />
            </Modal>

            {!canGoBack && <Modal isVisible={cancel} setIsVisible={setCancel}>
                <Dialog
                    variant="vertical"
                    icon={LogoutIcon}
                    description="Are you sure you want to exit? Because on exiting you’ll loose all the progress."
                    title={'You’ll loose all the progress!'}
                    primaryButtonText="Yes, Exit"
                    onPrimaryButtonClicked={navigateToValidator}
                    onSecondaryButtonClicked={() => setCancel(false)}
                    secondaryButtonText="Cancel"
                    buttonVariant="error"
                />
            </Modal>}

        </StyledContainer>
    );
};

export default DnfChecklist;
