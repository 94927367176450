import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { fetchPolicy } from '../../../redux/slices/PolicySlice/thunk';
import { IPolicyPlan } from '../../../redux/slices/PolicySlice/types';

const usePolicies = (policyIds: string[]): [IPolicyPlan[], boolean, () => void] => {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState<Record<string, boolean>>({});
    const [policies, setPolicies] = React.useState<IPolicyPlan[]>([]);
    const policyList = useSelector((state: ReduxState) => state.policySlice.policyList);
    const loadingRef = React.useRef<Record<string, boolean>>({});

    const fetchPolicies = ()=> {
        if (!policyIds.length) {
            return;
        }
        const policyDetails = policyList.data?.filter((p) => p?.id && policyIds.includes(p.id)) || [];
        if (policyDetails.length >= policyIds.length) {
            setPolicies(policyDetails);
        } else {
            const available = policyDetails.map((p) => p?.id || '');
            const unavailable = policyIds.filter((id) => !available.includes(id)) || [];
            let l = {};
            unavailable.map((p) => {
                l = {
                    ...l,
                    [p]: true
                };
            });
            setLoading(l);
            loadingRef.current = l;
            unavailable.forEach(async (p) => {
                await dispatch(fetchPolicy({ policyId: p, setLoadingOff: setLoadingOff }));
            });
        }
    };

    React.useEffect(() => {
        fetchPolicies();
    }, [JSON.stringify(policyIds)]);

    React.useEffect(() => {
        if (policyList.loading) return;
        const policyDetails = policyList.data?.filter((p) => p?.id && policyIds.includes(p.id)) || [];
        if (policyDetails.length) {
            setPolicies(policyDetails);
        }
    }, [policyList]);

    React.useEffect(() => {
        loadingRef.current = loading;
    }, [loading]);

    const setLoadingOff = (pId: string) => {
        loadingRef.current = {
            ...loadingRef.current,
            [pId]: false
        };
        setLoading(loadingRef.current);
    };

    if (policyIds.length === 0) {
        return [[] as IPolicyPlan[], false, fetchPolicies];
    }

    return [
        policies,
        Object.keys(loading)
            .map((k) => loading[k])
            .reduce((acc, curr) => acc || curr, false),
        fetchPolicies
    ];
};

export default usePolicies;
