import React, { useState } from 'react';

import { PolicyIdentity, PolicyCardDetail } from '../../atoms';
import { getPolicyType } from '../../../utils/common';
import { StyledContainer, StyledRowContainer } from '../PolicyCard/styles';
import { ICompanyPoliciesCard } from './types';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../utils/constants/Routes';
import { useNavigate } from 'react-router-dom';
import { AlertIcon } from '../../../assets/img';
import { AttachCDAccountModal } from '../AttachCDAccountModal';
import Banner from '../../atoms/Banner';
import { isCdAccountEnabled } from '../../../utils/featureFlags';
import { Typography } from '@loophealth/loop-ui-web-library';
import { useSelector } from 'react-redux';
import { ICDAccount } from '../../../redux/slices/CDAccountSlice/types';
import { ReduxState } from '../../../redux';

const CompanyPoliciesCard: React.FunctionComponent<ICompanyPoliciesCard> = ({
    insurerLogo,
    insurerName,
    id,
    policyPlacementStatus,
    formattedDate,
    policyType,
    isTopUpPolicy,
    isParentalPolicy,
    companyId,
    companyName,
    isActive,
    isCdAccountPresent,
    refreshPolicyList
}) => {
    const [isAttachCDAccountModalVisible, setAttachCDAccountModalVisible] = useState(false);
    const navigate = useNavigate();
    const onClick = (mode: 'Edit' | 'View' | 'Ecard') => {
        if (mode === 'Edit') {
            navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.PLACEMENT_FORM.route}?id=${companyId}&policyId=${id}`);
        } else if (mode === 'View') {
            navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.POLICY_DETAILS.route}?id=${id}`);
        } else if (mode === 'Ecard') {
            const baseURL =
                `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/Ecards/` + '';
            window.open(baseURL, '_blank');
        }
    };

    const navigateToCdLedger = (id: string) => {
        navigate(`${EXTERNAL_ROUTES.APP}/${INTERNAL_ROUTES.CD_LEDGER.route}?id=${id}`);
    };

    const cdAccountList: ICDAccount[] | null = useSelector(
        (state: ReduxState) => state.cdAccountSlice.cdAccountList
    )?.data;

    const linkedCdAccount = cdAccountList
        ? cdAccountList.filter((data) => data.companyId === companyId && data.policies.includes(id))[0]
        : null;

    const toggleAttachCDAccountModal = () => {
        setAttachCDAccountModalVisible(true);
    };

    return (
        <StyledContainer>
            <StyledRowContainer>
                <PolicyIdentity
                    policyCategory={getPolicyType(policyType, isTopUpPolicy, isParentalPolicy)}
                    policyImage={insurerLogo}
                    policyName={insurerName}
                    policyId={id}
                    policyStatus={policyPlacementStatus}
                />

                <PolicyCardDetail
                    policyEndDate={formattedDate}
                    policyStatus={policyPlacementStatus}
                    onClick={onClick}
                    displayEcard={false}
                    slabId={''}
                    sumInsured={0}
                    isActive={isActive}
                />
            </StyledRowContainer>
            {isCdAccountPresent || !isActive || !isCdAccountEnabled ? null : (
                <>
                    <Banner
                        isActionable={true}
                        action="Attach a CD Account"
                        onAction={toggleAttachCDAccountModal}
                        mode={'error'}
                    >
                        <img src={AlertIcon} alt="banner-icon" />
                        <span>{'CD Account is missing'}</span>
                    </Banner>
                    <AttachCDAccountModal
                        isVisible={isAttachCDAccountModalVisible}
                        setIsVisible={setAttachCDAccountModalVisible}
                        insurerLogo={insurerLogo}
                        insurerName={insurerName}
                        policyType={policyType}
                        companyName={companyName}
                        companyId={companyId}
                        policyId={id}
                        refreshPolicyList={refreshPolicyList}
                    />
                </>
            )}
            {isCdAccountPresent && (
                <Banner
                    isActionable={true}
                    action="View CD Account"
                    onAction={() => navigateToCdLedger(linkedCdAccount?.id as string)}
                    mode={'success'}
                >
                    <Typography variant="small" color="primary">
                        CD Account ID:
                    </Typography>
                    <Typography variant="small" color="primary" weight="bold">
                        {linkedCdAccount?.accountNumber || 'N/A'}
                    </Typography>
                </Banner>
            )}
        </StyledContainer>
    );
};

export default CompanyPoliciesCard;
