import moment from 'moment';
import {
    IIdentifiedMidtermData,
    IIdentifiedMidterms,
    IMemberUpload,
    IMidtermOperation,
    IMidtermRequest,
    IRejectedEntries,
    IValidateMidtermOperation,
    IValidateMidterms
} from '../../../../../redux/slices/ValidatorSlice/types';
import { ITableData } from './types';

export const formatTableData = (
    uploadedData: Record<string, unknown>[],
    identifiedMidTerms: IIdentifiedMidterms[]
): ITableData[] => {
    const formatData: ITableData[] = [];
    identifiedMidTerms?.map((data, index) => {
        const userName = uploadedData?.find(
            (member) =>
                (member.employee_id === data.employeeId || member.userId === data.userId) &&
                (member.relationship_to_account_holders as string).toLowerCase() === data.relationship.toLowerCase() &&
                (member.name as string)?.toLowerCase() === data?.name?.toLowerCase()
        );

        const obj: ITableData = {
            'S.No.': index + 1,
            'Employee ID': data.employeeId,
            'Name': userName?.name as string,
            'Relationship': data.relationship,
            'Marriage Date': { marriageDate: undefined, index },
            'Found a DNF': { isSelected: null, index },
            'Coverage Date': { coverageDate: undefined, index }
        };
        formatData.push(obj);
    });

    return formatData;
};

export const generateValidateMidTermsRequest = (
    identifiedMidterms: IMidtermOperation | undefined,
    uploadedData: IMemberUpload | null,
    tableData: ITableData[]
): Record<string, unknown> => {
    const addRequest: IMidtermRequest[] = [];
    const editRequest: IMidtermRequest[] = [];
    identifiedMidterms?.add.map((addRecord) => {
        const userRecord = uploadedData?.add.find(
            (member) =>
                member.employee_id === addRecord.employeeId &&
                (member.relationship_to_account_holders as string).toLowerCase() ===
                    addRecord.relationship.toLowerCase() &&
                (member.name as string).toLowerCase() === addRecord.name.toLowerCase()
        );
        const tableRecord = tableData.find(
            (record) =>
                record['Employee ID'] === addRecord.employeeId &&
                record.Relationship.toLowerCase() === addRecord.relationship.toLowerCase() &&
                record.Name.toLowerCase() === addRecord.name.toLowerCase()
        );
        if (userRecord) {
            const obj = {
                employeeId: userRecord.employee_id,
                relationship: (userRecord.relationship_to_account_holders as string).toLowerCase(),
                dob: userRecord.date_of_birth,
                dateOfMarriage:
                    tableRecord?.['Marriage Date'].marriageDate &&
                    moment(tableRecord?.['Marriage Date'].marriageDate).format('DD/MM/YYYY'),
                name: tableRecord?.Name
            };
            addRequest.push(obj as unknown as IMidtermRequest);
        }
    });
    identifiedMidterms?.edit.map((editRecord) => {
        const userRecord = uploadedData?.edit.find(
            (member) =>
                member.userId === editRecord.userId &&
                (member.relationship_to_account_holders as string).toLowerCase() ===
                    editRecord.relationship.toLowerCase() &&
                (member.name as string).toLowerCase() === editRecord.name.toLowerCase()
        );
        const tableRecord = tableData.find(
            (record) =>
                record['Employee ID'] === editRecord.employeeId &&
                record.Relationship.toLowerCase() === editRecord.relationship.toLowerCase() &&
                record.Name.toLowerCase() === editRecord.name.toLowerCase()
        );
        if (userRecord) {
            const obj = {
                userId: editRecord.userId,
                employeeId: editRecord.employeeId,
                relationship: (userRecord.relationship_to_account_holders as string).toLowerCase(),
                dob: userRecord.date_of_birth,
                dateOfMarriage:
                    tableRecord?.['Marriage Date'].marriageDate &&
                    moment(tableRecord?.['Marriage Date'].marriageDate).format('DD/MM/YYYY'),
                name: tableRecord?.Name
            };
            editRequest.push(obj as unknown as IMidtermRequest);
        }
    });

    const addEditUsers = {
        add: addRequest,
        edit: editRequest
    };
    return addEditUsers;
};

export const isMarriageDateRequired = (index: number, tableData: ITableData[]): boolean => {
    if (tableData[index]['Relationship'] === 'spouse') {
        return true;
    }
    return false;
};

export const isCoverageDateRequired = (index: number, tableData: ITableData[]): boolean => {
    if (tableData[index]['Found a DNF'].isSelected) {
        return true;
    }
    return false;
};

export const isProceedActive = (data: ITableData[] | null, midtermData: IIdentifiedMidtermData | null): boolean => {
    if (data?.length && midtermData) {
        const isDnf = data.filter((record) => record['Found a DNF'].isSelected === true);
        const isDnfCount = isDnf.length;
        const isNonDnfCount = data.filter((record) => record['Found a DNF'].isSelected === false).length;
        const totalIdentifiedMidterms =
            midtermData.acceptedMidTermAdditions.add.length + midtermData.acceptedMidTermAdditions.edit.length;
        const isCoveragedateProvided =
            isDnfCount > 0
                ? isDnf?.filter((record) => record['Coverage Date'].coverageDate).length === isDnfCount
                : true;
        if (isDnfCount + isNonDnfCount === totalIdentifiedMidterms && isCoveragedateProvided) {
            return true;
        }
    }
    return false;
};

export const isMarriagedateMissing = (data: ITableData[] | null): boolean => {
    if (data) {
        return (
            data.filter((response) => response.Relationship === 'spouse' && !response['Marriage Date'].marriageDate)
                .length > 0
        );
    }
    return false;
};

export const getMinDate = (days: number): Date => {
    return new Date(new Date().setDate(new Date().getDate() - days));
};

export const getUpdatedRejectedEntries = (
    rejectedIdentifiedMidterms: IMidtermOperation | undefined,
    rejectedValidatedMidTerms: IValidateMidtermOperation,
    uploadedData: IMemberUpload | null,
    rejectedEntries: IRejectedEntries | null
): IRejectedEntries => {
    const updatedRejectedEntries = { ...rejectedEntries };
    const existingAddRecords = [...(updatedRejectedEntries.add || [])];
    const existingEditRecords = [...(updatedRejectedEntries.edit || [])];

    // Map Rejected records for Add Rejected entries Identification
    rejectedIdentifiedMidterms?.add.map((addRecord: IIdentifiedMidterms) => {
        const userRecord = uploadedData?.add.find(
            (member) =>
                member.employee_id === addRecord.employeeId &&
                (member.relationship_to_account_holders as string).toLowerCase() ===
                    addRecord.relationship.toLowerCase() &&
                (member.name as string).toLowerCase() === addRecord.name.toLowerCase()
        );
        existingAddRecords.push({ ...userRecord, errors: `{\"midterm\":\"${addRecord.error}\"}` });
    });

    // Map Rejected records for Edit Rejected entries validation
    rejectedIdentifiedMidterms?.edit.map((editRecord: IIdentifiedMidterms) => {
        const userRecord = uploadedData?.edit.find((member) => member.userId === editRecord.userId);
        existingEditRecords.push({ ...userRecord, errors: `{\"midterm\":\"${editRecord.error}\"}` });
    });

    // Map Rejected records for Add Rejected entries validation
    rejectedValidatedMidTerms.add.map((addRecord: IValidateMidterms) => {
        const userRecord = uploadedData?.add.find(
            (member) =>
                member.employee_id === addRecord.employeeId &&
                (member.relationship_to_account_holders as string).toLowerCase() ===
                    addRecord.relationship.toLowerCase() &&
                (member.name as string).toLowerCase() === addRecord.name.toLowerCase()
        );
        existingAddRecords.push({ ...userRecord, errors: `{\"midterm\":\"${addRecord.error}\"}` });
    });

    // Map Rejected records for Edit Rejected entries validation
    rejectedValidatedMidTerms.edit.map((editRecord: IValidateMidterms) => {
        const userRecord = uploadedData?.edit.find((member) => member.userId === editRecord.userId);
        existingEditRecords.push({ ...userRecord, errors: `{\"midterm\":\"${editRecord.error}\"}` });
    });

    updatedRejectedEntries.add = existingAddRecords;
    updatedRejectedEntries.edit = existingEditRecords;
    updatedRejectedEntries.offboard = updatedRejectedEntries.offboard || [];

    return updatedRejectedEntries as IRejectedEntries;
};

export const getMinCoverageDate = (tableData: ITableData[], index: number): Date => {
    if (tableData[index].Relationship === 'child') return getMinDate(60);
    else {
        const marriageDate = tableData[index]['Marriage Date'].marriageDate;
        return marriageDate ? marriageDate : getMinDate(45);
    }
};

export const getUpdatedCoverageDates = (
    currentRecord: ITableData,
    uploadedData: IMemberUpload | null,
    identifiedMidterms: IIdentifiedMidtermData | null
): IMemberUpload => {
    const dataToUpload = { ...uploadedData };
    const updatedAddMemberUpload = uploadedData?.add.find(
        (addRecord) =>
            (addRecord.name as string).toLowerCase() === currentRecord.Name.toLowerCase() &&
            addRecord.employee_id === currentRecord['Employee ID'] &&
            (addRecord.relationship_to_account_holders as string).toLowerCase() === currentRecord.Relationship
    );

    const edituser = identifiedMidterms?.acceptedMidTermAdditions.edit.find(
        (user) =>
            user.employeeId === currentRecord['Employee ID'] &&
            user.name.toLowerCase() === currentRecord.Name.toLowerCase() &&
            user.relationship.toLowerCase() === currentRecord.Relationship.toLowerCase()
    );
    const updatedEditMemberUpload =
        edituser &&
        uploadedData?.edit.find(
            (editRecord) =>
                (editRecord.name as string).toLowerCase() === currentRecord.Name.toLowerCase() &&
                editRecord.userId === edituser.userId &&
                (editRecord.relationship_to_account_holders as string).toLowerCase() === currentRecord.Relationship
        );

    if (updatedAddMemberUpload) {
        const remainingRecords = uploadedData?.add.filter(
            (addRecord) =>
                !(
                    (addRecord.name as string).toLowerCase() === currentRecord.Name.toLowerCase() &&
                    addRecord.employee_id === currentRecord['Employee ID'] &&
                    (addRecord.relationship_to_account_holders as string).toLowerCase() === currentRecord.Relationship
                )
        );
        const updatedRecord = {
            ...updatedAddMemberUpload,
            policy_start_date: moment(currentRecord['Coverage Date'].coverageDate).format('DD/MM/YYYY')
        };
        remainingRecords?.push(updatedRecord);
        dataToUpload.add = remainingRecords;
    }
    if (updatedEditMemberUpload) {
        const remainingRecords = uploadedData?.edit.filter(
            (editRecord) =>
                !(
                    (editRecord.name as string).toLowerCase() === currentRecord.Name.toLowerCase() &&
                    editRecord.userId === edituser.userId &&
                    (editRecord.relationship_to_account_holders as string).toLowerCase() === currentRecord.Relationship
                )
        );
        const updatedRecord = {
            ...updatedEditMemberUpload,
            policy_start_date: moment(currentRecord['Coverage Date'].coverageDate).format('DD/MM/YYYY')
        };
        remainingRecords?.push(updatedRecord);
        dataToUpload.edit = remainingRecords;
    }

    return dataToUpload as IMemberUpload;
};
