import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Typography,
    Chip,
    UploadPillSelection,
    LoopButton,
    FullScreenLoader
} from '@loophealth/loop-ui-web-library';
import { ReduxState } from '../../../../../redux';
import { ExcelIcon, ValidatorView } from '../../../../../assets/img';
import {
    StyledContainer,
    StyledSpacingWrapper,
    StyledSubContainer,
    StyledChipWrapper,
    StyledPillWrapper,
    StyledUploadContainer,
    StyledAlignment,
    StyledButtonContainer,
    StyledBodyContainer,
    StyledFooter,
    StyledTemplateCard,
    StyledIconAlignment,
    StyledTemplateAlignment,
    StyledImage,
    StyledSpacing,
    StyleEnrolmentPillWrapper
} from './styles';
import {
    generateIdentifyKycRequest,
    generateIdentifyMidTermRequest,
    prepareCompanyFilter,
    preparePolicyFilter
} from './utils';
import { IDropdownItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types';
import {
    resetState,
    setCompanyId,
    setEnableEnrolmentPortal,
    setIdentifiedKyc,
    setIdentifiedMidterms,
    setMembersToUpload,
    setMethod,
    setMode,
    setPolicyId
} from '../../../../../redux/slices/ValidatorSlice';
import { fetchActivePoliciesByCompanyId, identifyKyc, identifyMidterms } from '../../../../../adapters';
import { fileDownload, showApiFailureToast } from '../../../../../utils/common';
import { EXCEL_TEMPLATES } from '../../../../../utils/constants/TemplateFilepaths';
import { useToast } from '../../../../hooks/useToast';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { useNavigate } from 'react-router-dom';
import useUploadExcel from './hooks/useUploadExcel';

const Validator: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const toast = useToast();
    const navigate = useNavigate();
    const companyList = useSelector((state: ReduxState) => state.companySlice.companyList.data) || [];
    const [companies, setCompanies] = React.useState<IDropdownItem[]>([]);
    const selectedCompanyId = useSelector((state: ReduxState) => state.validatorSlice.companyId) || '';
    const appendToEndorsements = useSelector((state: ReduxState) => state.validatorSlice.withEndorsements);
    const mode = useSelector((state: ReduxState) => state.validatorSlice.mode);
    const [policyList, setPolicyList] = React.useState<IDropdownItem[]>([]);
    const selectedPolicyId = useSelector((state: ReduxState) => state.validatorSlice.policyId) || '';
    const uploadedData = useSelector((state: ReduxState) => state.validatorSlice.membersToUpload);
    const identifiedKyc = useSelector((state: ReduxState) => state.validatorSlice.identifiedKyc);
    const identifiedMidterms = useSelector((state: ReduxState) => state.validatorSlice.identifiedMidTerms);
    const { setUploadError, setUploadedFile, uploadedFile, uploadFile } = useUploadExcel();
    const isEnrolmentPortalEnabled = useSelector((state: ReduxState) => state.validatorSlice.enableEnrolmentPortal);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    useEffect(() => {
        dispatch(resetState());
    }, []);

    // Filter company list to be displayed in dropdown when available
    useEffect(() => {
        if (companyList) {
            setCompanies(prepareCompanyFilter(companyList));
        }
    }, [companyList]);

    // Zero state navigation
    useEffect(() => {
        if (!isLoading) {
            if (identifiedKyc?.EDIT.length || identifiedKyc?.OFFBOARD.length) {
                navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.KYC_CHECKLIST.route);
            } else if (
                identifiedMidterms?.acceptedMidTermAdditions?.add?.length ||
                identifiedMidterms?.acceptedMidTermAdditions?.edit?.length
            ) {
                navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.DNF_CHECKLIST.route);
            } else if (uploadedData) {
                navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.VALIDATION_SUMMARY.route);
            }
        }
    }, [isLoading]);

    // Fetch PolicyList everytime companyId changes
    const handleCompanyChange = async (companyId: string) => {
        dispatch(resetState());
        setPolicyList([]);
        setUploadedFile([]);
        dispatch(setCompanyId(companyId));
        try {
            const response = await fetchActivePoliciesByCompanyId(companyId);
            if (response.data) {
                setPolicyList(preparePolicyFilter(response.data));
            }
        } catch (error) {
            showApiFailureToast(toast, (error as Error).toString());
        }
    };

    const validateData = async () => {
        const addEditUsers = generateIdentifyMidTermRequest(uploadedData);
        const userIds = generateIdentifyKycRequest(uploadedData);

        try {
            setIsLoading(true);
            const kycPromise = identifyKyc(userIds, selectedPolicyId);
            const midtermPromise = identifyMidterms(addEditUsers, selectedPolicyId, selectedCompanyId);
            const [kycResponse, midtermResponse] = await Promise.all([kycPromise, midtermPromise]);
            dispatch(setIdentifiedKyc(kycResponse.data));
            dispatch(setIdentifiedMidterms(midtermResponse.data));
            setIsLoading(false);
        } catch (error) {
            showApiFailureToast(toast, (error as Error).toString());
        }
    };

    const handleEnrolmentPortalActivation = (status: boolean) => {
        dispatch(setEnableEnrolmentPortal(status));
    };

    const onExcelUpload = async (files: Record<string, unknown>[]) => {
        if (files.length) {
            setUploadedFile(files);
            await uploadFile(files);
        } else {
            setUploadedFile([]);
            dispatch(setMembersToUpload(null));
        }
    };

    if (isLoading) {
        return (
            <FullScreenLoader
                title="Before validating the Data..."
                subTitle="We’re doing some pre-checks for edit requests and mid term additions"
            />
        );
    }

    return (
        <StyledContainer>
            <div data-testid="bulk-validator-image">
                <StyledImage src={ValidatorView} />
            </div>
            <StyledSubContainer data-testid="bulk-validator">
                <StyledBodyContainer>
                    <Typography variant="small" weight="semiBold">
                        Company Name
                    </Typography>
                    <StyledSpacingWrapper>
                        <Form.Dropdown
                            placeholder="Select the company name"
                            listItems={companies}
                            onClick={handleCompanyChange}
                            selectedItem={selectedCompanyId}
                            searchable={true}
                        />
                    </StyledSpacingWrapper>
                    <Typography variant="small" weight="semiBold">
                        Choose your upload method
                    </Typography>
                    <StyledChipWrapper>
                        <Chip
                            key={'Endorsement'}
                            label={'Create or Update Endorsement Request'}
                            isActive={!!appendToEndorsements}
                            onClick={() => dispatch(setMethod(true))}
                        />
                        <Chip
                            key={'Roaster'}
                            label={'Update Active Roster'}
                            isActive={appendToEndorsements == false}
                            onClick={() => dispatch(setMethod(false))}
                        />
                    </StyledChipWrapper>
                    {selectedCompanyId && appendToEndorsements !== null && (
                        <>
                            <Typography variant="small" weight="semiBold">
                                Select action you want to take
                            </Typography>
                            <StyledPillWrapper>
                                <Chip
                                    key={'Validate'}
                                    label={'Add, Edit and Delete'}
                                    isActive={mode === 'Validate'}
                                    onClick={() => dispatch(setMode('Validate'))}
                                />
                                {/* <Chip
                                    key={'Ecards'}
                                    label={'Autogenerate E-cards'}
                                    isActive={mode === 'Autogenerate'}
                                    onClick={() => dispatch(setMode('Autogenerate'))}
                                /> */}
                            </StyledPillWrapper>
                        </>
                    )}

                    {mode && (
                        <>
                            <Typography variant="small" weight="semiBold">
                                Policy Name
                            </Typography>
                            <StyledSpacingWrapper>
                                <Form.Dropdown
                                    placeholder="Select the Policy"
                                    listItems={policyList}
                                    onClick={(policyId) => dispatch(setPolicyId(policyId))}
                                    selectedItem={selectedPolicyId}
                                    searchable={true}
                                    emptyState={`Selected company don't have any active policy available`}
                                />
                                <StyledSpacingWrapper>
                                    <Typography variant="small" weight="regular" color="secondary">
                                        Selection mandatory if your endorsement includes add and edit data
                                    </Typography>
                                </StyledSpacingWrapper>
                            </StyledSpacingWrapper>
                        </>
                    )}

                    {selectedPolicyId && (
                        <StyledSpacing>
                            <Typography variant="small" weight="semiBold">
                                Do you want to activate enrolment portal for these members?
                            </Typography>
                            <StyleEnrolmentPillWrapper>
                                <Chip
                                    key={'active'}
                                    label={'Yes, activate'}
                                    isActive={isEnrolmentPortalEnabled === true}
                                    onClick={() => handleEnrolmentPortalActivation(true)}
                                />
                                <Chip
                                    key={'inactive'}
                                    label={`No, Don't activate`}
                                    isActive={isEnrolmentPortalEnabled === false}
                                    onClick={() => handleEnrolmentPortalActivation(false)}
                                />
                            </StyleEnrolmentPillWrapper>
                        </StyledSpacing>
                    )}

                    {isEnrolmentPortalEnabled !== null && (
                        <>
                            <StyledUploadContainer $isFileUploaded={!!uploadedFile.length}>
                                <UploadPillSelection
                                    formats={['.xls', '.xlsx']}
                                    onUpload={onExcelUpload}
                                    selected={uploadedFile}
                                    onError={(error) => setUploadError(error.message)}
                                    maxSize={25}
                                />
                            </StyledUploadContainer>
                            {!uploadedFile.length && (
                                <>
                                    <StyledAlignment>
                                        <Typography variant="extraSmall" weight="regular" color="secondary">
                                            Supported Formats: XLS, XLSX
                                        </Typography>
                                        <Typography variant="extraSmall" weight="regular" color="secondary">
                                            Maximum Size: 25MB
                                        </Typography>
                                    </StyledAlignment>

                                    <StyledTemplateCard>
                                        <StyledTemplateAlignment>
                                            <div>
                                                <StyledIconAlignment>
                                                    <img src={ExcelIcon} alt="excel" />
                                                    <Typography variant="extraSmall" weight="semiBold" color="primary">
                                                        Add, Edit and Delete Template
                                                    </Typography>
                                                </StyledIconAlignment>
                                                <Typography variant="extraSmall" weight="medium" color="secondary">
                                                    You can use this template as a starting point of your file.
                                                </Typography>
                                            </div>
                                            <div>
                                                <LoopButton
                                                    variant={'secondary'}
                                                    size="small"
                                                    onClick={() => fileDownload(EXCEL_TEMPLATES.VALIDATOR)}
                                                >
                                                    Download
                                                </LoopButton>
                                            </div>
                                        </StyledTemplateAlignment>
                                    </StyledTemplateCard>
                                </>
                            )}
                        </>
                    )}
                </StyledBodyContainer>

                <StyledFooter>
                    <StyledButtonContainer>
                        <LoopButton
                            variant={uploadedData && isEnrolmentPortalEnabled !== null ? 'filled' : 'disabled'}
                            onClick={validateData}
                        >
                            Run Validations
                        </LoopButton>
                    </StyledButtonContainer>
                </StyledFooter>
            </StyledSubContainer>
        </StyledContainer>
    );
};

export default Validator;
