import React from 'react';
import {
    SelectCompanyPlaceholderContainer,
    StyledDropdownWrapper,
    StyledEnrolmentPolicyListingContainer,
    StyledPolicyScrollContainer,
    StyledStepsContainer
} from './styles';
import ConfigStepHeader from './ConfigStepHeader';
import { Form, Typography } from '@loophealth/loop-ui-web-library';
import { EnrolmentPolicyIcon } from '../../../../../assets/img';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import usePolicies from '../../../../hooks/business-hooks/usePolicies';
import { IPolicyPlan } from '../../../../../redux/slices/PolicySlice/types';
import SelectablePolicyComponent from '../../../../containers/SelectablePolicyComponent/index';
import { ICompanyPoliciesComponentProps } from './types';

const CompanyPoliciesComponent: React.FC<ICompanyPoliciesComponentProps> = ({
    selectedCompany,
    setSelectedCompany,
    selectedPolicies,
    setSelectedPolicies
}) => {
    const companyList = useSelector((state: ReduxState) => state.companySlice.companyList?.data);
    const [policyList, isPolicyListLoading] = usePolicies(selectedCompany?.policyPlans || []);

    const activePolicyList = policyList.filter((p: IPolicyPlan) => p.active);
    const companyDropdownList =
        companyList?.map((companyData) => {
            return { name: companyData.name, value: companyData.id };
        }) || [];

    const onCompanySelect = (companyId: string) => {
        setSelectedCompany(companyList?.find((data) => data.id === companyId) || null);
    };
    const onPolicySelected = (policyId: string, isSelected: boolean) => {
        const selectedPolicy = activePolicyList.find((policy) => policy.id === policyId);
        if (!selectedPolicy) return;
        let currentPolicies = [...selectedPolicies];
        if (isSelected) {
            currentPolicies.push(selectedPolicy);
        } else {
            currentPolicies = currentPolicies.filter((policy) => policy.id !== policyId);
        }
        setSelectedPolicies(currentPolicies);
    };

    const EnrolmentPolicyListing = () => {
        const currentPolicies = [...selectedPolicies];
        return (
            <StyledEnrolmentPolicyListingContainer>
                <Typography variant={'small'} color={'secondary'}>
                    Open enrolment portal for:
                </Typography>
                <StyledPolicyScrollContainer>
                    {activePolicyList.map((data, index) => {
                        const isPolicySelected = !!currentPolicies.find((policy) => policy.id === data.id);
                        return (
                            <SelectablePolicyComponent
                                key={index}
                                policyId={data.id}
                                isSelected={isPolicySelected}
                                policyType={data.policyType}
                                icon={data.insurerLogo}
                                insurer={data.insurerName}
                                onSelect={onPolicySelected}
                            />
                        );
                    })}
                </StyledPolicyScrollContainer>
            </StyledEnrolmentPolicyListingContainer>
        );
    };

    return (
        <StyledStepsContainer>
            <ConfigStepHeader
                icon={EnrolmentPolicyIcon}
                title="Step 1: Company & policy"
                isRequired={true}
                description="Choose a company and policies to create a campaign"
            />
            <StyledDropdownWrapper>
                <Form.Dropdown
                    placeholder="Select Company"
                    onClick={onCompanySelect}
                    listItems={companyDropdownList}
                    selectedItem={selectedCompany?.id || ''}
                />
            </StyledDropdownWrapper>
            {selectedCompany && !isPolicyListLoading ? (
                <EnrolmentPolicyListing />
            ) : (
                <SelectCompanyPlaceholderContainer>
                    <Typography variant="medium" weight="medium">
                        {isPolicyListLoading
                            ? 'Loading policies'
                            : 'Select a company to show policies associated with it'}
                    </Typography>
                </SelectCompanyPlaceholderContainer>
            )}
        </StyledStepsContainer>
    );
};
export default CompanyPoliciesComponent;
