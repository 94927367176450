import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 16px 56px;
  gap: 24px;
`;
export const StyledStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  border-radius: 20px;
  gap: 16px;
  background-color: ${(p) => p.theme.colors.secondary};
`;
export const StyledDropdownWrapper = styled.div`
  width: 60%;
`;
export const StyledStepHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
export const StyledConfigTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const SelectCompanyPlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  height: 162px;
  background-color: ${(p) => p.theme.colors.white};
`;
export const StyledEnrolmentPolicyListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const StyledPolicyScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
export const StyledEnrolmentTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0 8px;
`;
export const StyledDatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 60%;

  div {
    flex: 1;
  }
`;
