import { IValidatorSlice } from './types';

export const initialState: IValidatorSlice = {
    companyId: null,
    policyId: null,
    withEndorsements: null,
    mode: null,
    membersToUpload: null,
    rejectedEntries: null,
    enableEnrolmentPortal: null,
    identifiedKyc: null,
    identifiedMidTerms: null,
    dnfTableData: null
};
