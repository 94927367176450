import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

const validatorSlice = createSlice({
    name: 'validatorSlice',
    initialState,
    reducers: {
        resetState(state) {
            state.companyId = initialState.companyId;
            state.policyId = initialState.policyId;
            state.mode = initialState.mode;
            state.withEndorsements = initialState.withEndorsements;
            state.membersToUpload = initialState.membersToUpload;
            state.rejectedEntries = initialState.rejectedEntries;
            state.identifiedKyc = initialState.identifiedKyc;
            state.identifiedMidTerms = initialState.identifiedMidTerms;
            state.enableEnrolmentPortal = initialState.enableEnrolmentPortal;
            state.dnfTableData = initialState.dnfTableData;
        },
        setCompanyId(state, { payload }) {
            state.companyId = payload;
        },
        setPolicyId(state, { payload }) {
            state.policyId = payload;
        },
        setMode(state, { payload }) {
            state.mode = payload;
        },
        setMethod(state, { payload }) {
            state.withEndorsements = payload;
        },
        setMembersToUpload(state, { payload }) {
            state.membersToUpload = payload;
        },
        setRejectedEntries(state, { payload }) {
            state.rejectedEntries = payload;
        },
        setEnableEnrolmentPortal(state, { payload }) {
            state.enableEnrolmentPortal = payload;
        },
        setIdentifiedKyc(state, { payload }) {
            state.identifiedKyc = payload;
        },
        setIdentifiedMidterms(state, { payload }) {
            state.identifiedMidTerms = payload;
        },
        setDnfTableData(state, { payload }) {
            state.dnfTableData = payload;
        }
    },
    extraReducers: {
        'common/cleanup': (state) => {
            state.companyId = initialState.companyId;
            state.policyId = initialState.policyId;
            state.mode = initialState.mode;
            state.withEndorsements = initialState.withEndorsements;
            state.membersToUpload = initialState.membersToUpload;
            state.rejectedEntries = initialState.rejectedEntries;
            state.identifiedKyc = initialState.identifiedKyc;
            state.identifiedMidTerms = initialState.identifiedMidTerms;
            state.enableEnrolmentPortal = initialState.enableEnrolmentPortal;
            state.dnfTableData = initialState.dnfTableData;
        }
    }
});
export default validatorSlice.reducer;
export const {
    resetState,
    setCompanyId,
    setPolicyId,
    setMode,
    setMethod,
    setMembersToUpload,
    setRejectedEntries,
    setEnableEnrolmentPortal,
    setIdentifiedKyc,
    setIdentifiedMidterms,
    setDnfTableData
} = validatorSlice.actions;
