import React, { FC, useState } from 'react';
import { IPolicyForm } from './types';
import {
    StyledHorizontalDivider,
    StyledPolicyForm,
    StyledPolicyInputs,
    StyledPolicySubmitButton,
    StyledPolicySubmitButtonContainer,
    StyledRadioButtonContainer,
    StyledRadioInputGroup,
    StyledSelectedFile,
    StyledTemplateButton,
    StyledTemplateContainer,
    StyledTemplateInfo,
    StyledTemplateInfoTitleContainer,
    StyledUploadPill,
    StyledUploadPillContainer,
    StyledUploadPillHelper
} from './styles';
import { Dialog, Form, LoopButton, Modal, Typography, UploadPillSelection } from '@loophealth/loop-ui-web-library';
import { POLICY_TYPE } from '../../../../utils/constants/PolicyTypeConstants';
import { IPolicy, IPolicyType } from '../../../../redux/slices/PolicySlice/types';
import { ExcelIcon, TrashIconCircle } from '../../../../assets/img';
import {
    IRadioButtonOptions,
    policyTypeDetails,
    radioButtonOptions
} from '../../../../utils/constants/PlacementSlips/RadioButtonOptions';
import UploadModalFile from '../../../atoms/UploadModal/UploadModalFile';
import { excelToJson, fileDownload, placementDataFormatter } from '../../../../utils/common';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setPlacementFormDetails } from '../../../../redux/slices/PolicySlice';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../utils/constants/Routes';
// import { assignNickName } from '../../../../adapters';

const PolicyForm: FC<IPolicyForm> = ({ companyId, activePolicyList }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const placementSlip = React.useRef<Record<string, unknown>[]>([]);
    const [policyType, setPolicyType] = React.useState<IPolicyType | ''>('');
    const [isTopUpPolicy, setIsTopUpPolicy] = React.useState<IRadioButtonOptions | null>(null);
    const [isParentalPolicy, setIsParentalPolicy] = React.useState<IRadioButtonOptions | null>(null);
    const [basePolicyPlanId, setBasePolicyPlanId] = React.useState<string>('');
    const [selectedFiles, setSelectedFiles] = React.useState<Record<string, unknown>[]>([]);
    const [fileError, setFileError] = useState<Error | null>(null);
    const [showDeleteFilePrompt, setShowDeleteFilePrompt] = useState(false);
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState<boolean>(false);

    const basePolicyPlans =
        activePolicyList?.filter(
            (policy) => policy.policyType === policyType && policy.policyPlacementStatus !== 'Draft'
        ) || [];

    const navigateToPlacementForm = (id: string) => {
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.PLACEMENT_FORM.route + '?id=' + id);
    };

    const handleContinueClick = () => {
        excelToJson(
            selectedFiles[0] as unknown as Blob,
            async (excelJSON) => {
                try {
                    setError('');

                    setLoading(true);
                    // const [error, assignNickNameResponse] =
                    //     await parseResponse(assignNickName(policyId, policyNickname.trim()));
                    // if (error || assignNickNameResponse.status != 200) {
                    //     setPolicyNicknameError(
                    //         'Policy nickname already exists. Refer guidelines to create a unique nickname.'
                    //     );
                    // setLoading(false);
                    //     return;
                    // }

                    placementSlip.current = excelJSON;
                    if (placementSlip.current && policyType) {
                        const placementDetails: IPolicy = placementDataFormatter(
                            placementSlip.current,
                            policyType
                        ) as unknown as IPolicy;

                        if (placementDetails.error) {
                            setError(placementDetails.error);
                            setLoading(false);
                        } else {
                            if (placementDetails.policyType.toLowerCase() !== 'gmc') {
                                placementDetails['sumInsuredApproach'] = 'Individual';
                            }

                            if (!placementDetails.policyStartDate) {
                                placementDetails.policyStartDate = new Date();
                                placementDetails.policyStartDate.setHours(0, 0, 0, 0);
                            }
                            if (!placementDetails.policyEndDate) {
                                placementDetails.policyEndDate = new Date();
                                placementDetails.policyEndDate.setHours(0, 0, 0, 0);
                            }
                            const isCommonPool =
                                placementDetails?.sumInsuredApproach.toLowerCase() === 'individual' ? false : true;

                            // Adding the mandatory fields not being recorded from the placement slip
                            const updatedDetails = {
                                ...placementDetails,
                                isTopUpPolicy: isTopUpPolicy?.id === 'true',
                                isParentalPolicy: isParentalPolicy?.id === 'true',
                                basePolicyPlanId: basePolicyPlanId,
                                companyId: companyId,
                                policyPlacementStatus: 'Draft',
                                isCommonPool: isCommonPool,
                                installmentPremium: placementDetails.installmentPremium || null,
                                finalAgreedRate: placementDetails.finalAgreedRate || null
                            };
                            dispatch(setPlacementFormDetails({ placementFormDetails: updatedDetails }));
                            setLoading(false);
                            navigateToPlacementForm(companyId);
                        }
                    }
                } catch (e) {
                    setLoading(false);
                    throw e;
                }
            },
            'Policy Data'
        );
    };

    const handlePolicyChange = (policyType: string) => {
        setPolicyType(POLICY_TYPE.filter((p) => p === policyType)[0]);
    };

    const handleTemplateDownload = () => {
        const pType =
            policyType?.toLowerCase() === 'covid'
                ? policyType.charAt(0).toUpperCase() + policyType.slice(1).toLowerCase()
                : policyType;
        const fileName = `Template/(Template)-${pType}%20Placement%20Slip.xlsx`;
        fileDownload(fileName);
    };

    const handleFileDelete = () => {
        setShowDeleteFilePrompt(true);
    };

    const handleDeleteFileConfirmation = () => {
        setSelectedFiles([]);
        setShowDeleteFilePrompt(false);
    };

    const getButtonVariant = () => {
        if (
            policyType &&
            (policyType?.toLowerCase() === 'gmc' ? isParentalPolicy : true) &&
            selectedFiles.length &&
            (isTopUpPolicy?.id === 'false' || basePolicyPlanId)
        ) return 'filled';
        return 'disabled';
    };

    return (
        <StyledPolicyForm>
            <Modal
                isVisible={showDeleteFilePrompt}
                setIsVisible={setShowDeleteFilePrompt}
            >
                <Dialog
                    variant="vertical"
                    icon={TrashIconCircle}
                    description="Are you sure you want to delete this file? You'll have to re-upload it."
                    title={`Deleting “Placement Slip File”?`}
                    primaryButtonText="Yes, Delete"
                    buttonVariant='error'
                    onPrimaryButtonClicked={handleDeleteFileConfirmation}
                    onSecondaryButtonClicked={() => setShowDeleteFilePrompt(false)}
                    secondaryButtonText="Cancel"
                />
            </Modal>
            <StyledPolicyInputs>
                <Form.FormField id='policy-dropdown' label='Select Policy Type'>
                    <Form.Dropdown listItems={POLICY_TYPE.map((policyType) => ({
                        name: policyType,
                        value: policyType
                    }))} onClick={handlePolicyChange} placeholder='Please select' selectedItem={policyType} />
                </Form.FormField>

                <StyledRadioInputGroup>
                    {policyType && (
                        <Form.FormField
                            id={policyTypeDetails[0].name}
                            label={policyTypeDetails[0].label}
                            required={policyTypeDetails[0].isMandatory}
                        >
                            <StyledRadioButtonContainer>
                                <Form.RadioButton
                                    radioItems={radioButtonOptions}
                                    selectedItem={isTopUpPolicy}
                                    onClick={setIsTopUpPolicy}
                                />
                            </StyledRadioButtonContainer>
                        </Form.FormField>
                    )}

                    {policyType?.toLowerCase() === 'gmc' && (
                        <Form.FormField
                            id={policyTypeDetails[1].name}
                            label={policyTypeDetails[1].label}
                            required={policyTypeDetails[1].isMandatory}
                        >
                            <StyledRadioButtonContainer>
                                <Form.RadioButton
                                    radioItems={radioButtonOptions}
                                    selectedItem={isParentalPolicy}
                                    onClick={setIsParentalPolicy}
                                />
                            </StyledRadioButtonContainer>
                        </Form.FormField>
                    )}
                </StyledRadioInputGroup>

                {policyType && isTopUpPolicy?.id === 'true' && (
                    <Form.FormField
                        id='top-up-dropdown'
                        label='Please Select a Base Policy for Top-Up'
                        required={true}
                    >
                        <Form.Dropdown
                            listItems={basePolicyPlans.map((basePolicyPlan) => ({
                                value: basePolicyPlan.id,
                                name: `
                                    ${basePolicyPlan.insurerName} 
                                    (${basePolicyPlan.policyType || 'N/A'}) (${basePolicyPlan.id || 'N/A'})
                                `
                            }))}
                            placeholder='Please select'
                            selectedItem={basePolicyPlanId}
                            onClick={setBasePolicyPlanId}
                        />
                    </Form.FormField>
                )}

                {
                    policyType &&
                        (policyType?.toLowerCase() === 'gmc' ? isParentalPolicy && isTopUpPolicy : isTopUpPolicy) && (
                        <>
                            <StyledHorizontalDivider />
                            <StyledUploadPillContainer>
                                {
                                    selectedFiles.length > 0
                                        ? <StyledSelectedFile>
                                            <UploadModalFile
                                                onDelete={handleFileDelete}
                                                fileName={
                                                    selectedFiles[0] instanceof File
                                                        ? selectedFiles[0].name
                                                        : 'Placement slip.xlsx'
                                                }
                                            />
                                        </StyledSelectedFile>
                                        : <StyledUploadPill>
                                            <UploadPillSelection
                                                formats={['.xls', '.xlsx']}
                                                onUpload={setSelectedFiles}
                                                selected={selectedFiles}
                                                onError={setFileError}
                                                maxFiles={1}
                                                maxSize={25}
                                            />
                                        </StyledUploadPill>
                                }
                                {
                                    (fileError || error) &&
                                    <Typography variant='extraSmall' color='error'>
                                        {fileError ? fileError.message : error}
                                    </Typography>
                                }
                                {
                                    selectedFiles.length === 0 &&
                                    <>
                                        <StyledUploadPillHelper>
                                            <Typography variant='extraSmall'>Supported Formats: XLS, XLSX</Typography>
                                            <Typography variant='extraSmall'>Maximum Size: 25MB</Typography>
                                        </StyledUploadPillHelper>

                                        <StyledTemplateContainer>
                                            <StyledTemplateInfo>
                                                <StyledTemplateInfoTitleContainer>
                                                    <img src={ExcelIcon} />
                                                    <Typography variant='extraSmall' weight='medium'>
                                                        Template for {policyType} Placement Slip
                                                    </Typography>
                                                </StyledTemplateInfoTitleContainer>
                                                <Typography variant='extraSmall'>
                                                    You can use this template as a starting point of your file.
                                                </Typography>
                                            </StyledTemplateInfo>
                                            <StyledTemplateButton>
                                                <LoopButton
                                                    variant='outline'
                                                    size='small'
                                                    onClick={handleTemplateDownload}
                                                >
                                                    Download
                                                </LoopButton>
                                            </StyledTemplateButton>
                                        </StyledTemplateContainer>
                                    </>
                                }
                            </StyledUploadPillContainer>
                        </>
                    )
                }
            </StyledPolicyInputs>

            <StyledPolicySubmitButtonContainer>
                <StyledPolicySubmitButton>
                    <LoopButton isLoading={loading} variant={getButtonVariant()} onClick={handleContinueClick}>
                        Continue
                    </LoopButton>
                </StyledPolicySubmitButton>
            </StyledPolicySubmitButtonContainer>
        </StyledPolicyForm>
    );
};

export default PolicyForm;
