import React from 'react';
import { StyledDatePickerContainer, StyledEnrolmentTypeContainer, StyledStepsContainer } from './styles';
import ConfigStepHeader from './ConfigStepHeader';
import { EnrolmentCalendarIcon } from '../../../../../assets/img';
import { Typography, Form } from '@loophealth/loop-ui-web-library';
import { IDateCycleSelectionComponentProps } from './types';

const DateCycleSelectionComponent: React.FC<IDateCycleSelectionComponentProps> = ({
    memberRegistrationStartDate, memberRegistrationEndDate, endoCycleStartDate, endoCycleEndDate,
    setMemberRegistrationStartDate, setMemberRegistrationEndDate, setEndoCycleStartDate, setEndoCycleEndDate
}) => {
    return (
        <StyledStepsContainer>
            <ConfigStepHeader icon={EnrolmentCalendarIcon} title='Step 2: Setup Start & end of cycle'
                description='Decide when enrolment portal opens for employees' isRequired={true}/>
            <StyledEnrolmentTypeContainer>
                <Typography variant='small' weight='medium' color='secondary'>Enrolment type:</Typography>
                <Typography variant='medium' weight='medium'>One time setup</Typography>
            </StyledEnrolmentTypeContainer>
            <Typography variant='small' weight='medium'>Member registration start and end date</Typography>
            <StyledDatePickerContainer>
                <Form.DatePicker value={memberRegistrationStartDate} onChange={setMemberRegistrationStartDate}/>
                <Form.DatePicker value={memberRegistrationEndDate} onChange={setMemberRegistrationEndDate}/>
            </StyledDatePickerContainer>
            <Typography variant='small' weight='medium'>Enrolment cycle start and end date</Typography>
            <StyledDatePickerContainer>
                <Form.DatePicker value={endoCycleStartDate} onChange={setEndoCycleStartDate}/>
                <Form.DatePicker value={endoCycleEndDate} onChange={setEndoCycleEndDate}/>
            </StyledDatePickerContainer>
        </StyledStepsContainer>
    );
};
export default DateCycleSelectionComponent;
