/* eslint-disable react/prop-types */
import {
    LoopButton,
    Modal,
    Toast,
    Typography,
    useTable
} from '@loophealth/loop-ui-web-library';
import React, { useEffect, useMemo } from 'react';
import {
    addFreshdeskComment,
    updateEndorsementStatusAPI
} from '../../../../../adapters';
import { EditIcon } from '../../../../../assets/img';
import WithThemeProvider from '../../../../../theme/WithThemeProvider';
import {
    groupArrayOfObjectsByKey,
    parseResponse
} from '../../../../../utils/common';
import {
    StyledButtonAlignment,
    StyledButtonSpacing,
    StyledFreshdeskMessagePre,
    StyledHorizontalButtonWrapper,
    StyledTextArea
} from '../../../../containers/ValidationSummaryDetails/styles';
import {
    StyledEmptySpace,
    StyledSpaceBetween
} from '../../../../containers/ValidationSummaryTable/styles';
import {
    formatCurrencyElseDefault,
    textPlaceholder
} from '../../../../containers/ValidationSummaryTable/utils';
import { DEFAULT_FRESHDESK_ENDO_MESSAGE } from './constant';
import {
    StyledFreshdeskMessageEditor,
    StyledFreshdeskMessageWrapper,
    StyledModalBody,
    StyledModalCTA,
    StyledModalContainer,
    StyledModalHeader,
    StyledToastWrapper
} from './styles';
import { IEndorsementFdModal } from './types';
import { renderToStaticMarkup } from 'react-dom/server';
import FreshdeskInfoTable from '../../../../containers/ValidationSummaryTable/tables/freshdeskTables/TableInfo';
import { spaceBetweenStyle } from '../../../../containers/ValidationSummaryTable/tables/freshdeskTables/styles';

const EndorsementFdModal: React.FC<IEndorsementFdModal> = ({
    endorsement,
    isCDBalanceSufficient,
    visible,
    setIsVisible,
    cdAccountOverview,
    nextStatus,
    updateEndorsementStatus
}) => {
    const [editModal, setEditModal] = React.useState(false);
    const [freshdeskMessage, setFreshdeskMessage] = React.useState<string>('');
    const [updatedFreshdeskMessage, setUpdatedFreshdeskMessage] =
        React.useState<string>(freshdeskMessage);
    const [isLoading, setIsLoading] = React.useState(false);

    const toast = Toast.useToast();

    useEffect(() => {
        setFreshdeskMessage(
            DEFAULT_FRESHDESK_ENDO_MESSAGE[
                isCDBalanceSufficient ? 'SUFFICIENT' : 'INSUFFICIENT'
            ]
        );
    }, [isCDBalanceSufficient]);

    const closeFreshdeskModal = () => !isLoading && setIsVisible(false);
    const cancelEditModal = () => {
        setEditModal(false);
        setIsVisible(true);
    };

    const onHandleEditClick = () => {
        setUpdatedFreshdeskMessage(freshdeskMessage);
        setIsVisible(false);
        setEditModal(true);
    };

    const handleSaveEditMessage = () => {
        setEditModal(false);
        setIsVisible(true);
        setFreshdeskMessage(updatedFreshdeskMessage);
    };

    const onFreshDeskTicketClicked = () => {
        if (!endorsement?.supportTicketId) return;
        window.open(`https://loophealth.freshdesk.com/a/tickets/${endorsement.supportTicketId}`);
    };

    const onSendMessage = async () => {
        if (!cdAccountOverview) {
            closeFreshdeskModal();
            toast?.error('Cd Account Missing');
            return;
        }
        if (!endorsement?.supportTicketId) {
            closeFreshdeskModal();
            toast?.error('Freshdesk Ticket Missing');
            return;
        }
        setIsLoading(true);

        const requestData = { endorsementStatus: nextStatus };
        const [error, updatedStateResponse] = await parseResponse(
            updateEndorsementStatusAPI(endorsement?.id || '', requestData)
        );
        if (error) {
            toast?.error(error.toString(), 'Unable to process');
            closeFreshdeskModal();
            setIsLoading(false);
            return;
        }

        const message = renderToStaticMarkup(<CostingTable />);
        addFreshdeskComment(endorsement.supportTicketId, message)
            .then(() => {
                toast?.success(
                    <StyledToastWrapper>
                        <Typography variant="medium" weight="medium" color="tertiary">
                            We&lsquo;ve successfully attached the message to Freshdesk.
                        </Typography>
                        <div>
                            <LoopButton
                                onClick={onFreshDeskTicketClicked}
                                variant="text"
                                size="large"
                                textColor="teamTurquoise"
                            >
                                Open Freshdesk
                            </LoopButton>
                        </div>
                    </StyledToastWrapper>,
                    '',
                    { variant: 'dark', expires: 0, closeOnClick: false, showCloseIcon: true }
                );
                closeFreshdeskModal();
                setIsLoading(false);
                if (updatedStateResponse.data?.status === nextStatus) {
                    updateEndorsementStatus(updatedStateResponse.data);
                }
            })
            .catch(() => {
                if (updatedStateResponse.data?.status === nextStatus) {
                    updateEndorsementStatus(updatedStateResponse.data);
                }
                setIsLoading(false);
                closeFreshdeskModal();
                toast?.error('Failed to add comment');
            });
    };

    const {
        ADD = 0,
        EDIT = 0,
        DELETE = 0
    } = useMemo(() => {
        if (endorsement?.memberData) {
            const { ADD, EDIT, DELETE } = groupArrayOfObjectsByKey(
                endorsement.memberData,
                (member) => member.operationType
            );
            return {
                ADD: ADD?.length,
                EDIT: new Set(EDIT?.map((member) => member.userId)).size,
                DELETE: DELETE?.length
            };
        } else return {};
    }, [endorsement?.memberData]);

    const amountRequired = Math.abs(
        (cdAccountOverview?.netCostOfEndorsement || 0) -
        (cdAccountOverview?.cdBalance || 0)
    );

    const isRefund = (cdAccountOverview?.netCostOfEndorsement || 0) < 0;

    const CostingTable: React.FC<{ edit?: boolean }> = ({ edit = false }) => {
        const { Table, Row, HeadCell, DataCell } = edit ? useTable('info') : FreshdeskInfoTable;
        return (<WithThemeProvider>
            <StyledFreshdeskMessageWrapper>
                <StyledFreshdeskMessageEditor>
                    <Typography variant="small">
                        <StyledFreshdeskMessagePre>
                            {freshdeskMessage}
                        </StyledFreshdeskMessagePre>
                    </Typography>
                    {edit && <div>
                        <LoopButton
                            variant="text"
                            iconSrc={EditIcon}
                            onClick={onHandleEditClick}
                        />
                    </div>}
                </StyledFreshdeskMessageEditor>

                <Table>
                    <Row>
                        <HeadCell></HeadCell>
                        <HeadCell>Correct Lives</HeadCell>
                        <HeadCell>{endorsement?.policyType} Cost</HeadCell>
                    </Row>
                    <Row>
                        <DataCell>
                            <Typography variant="small" weight="medium">
                                Add
                            </Typography>
                        </DataCell>
                        <DataCell>{textPlaceholder(ADD)}</DataCell>
                        <DataCell>
                            {formatCurrencyElseDefault(
                                cdAccountOverview?.additionCost
                            )}
                        </DataCell>
                    </Row>
                    <Row>
                        <DataCell>
                            <Typography variant="small" weight="medium">
                                Edit
                            </Typography>
                        </DataCell>
                        <DataCell>{textPlaceholder(EDIT)}</DataCell>
                        <DataCell>
                            {formatCurrencyElseDefault(cdAccountOverview?.editsCost)}
                        </DataCell>
                    </Row>
                    <Row>
                        <DataCell>
                            <Typography variant="small" weight="medium">
                                Delete
                            </Typography>
                        </DataCell>
                        <DataCell>{textPlaceholder(DELETE)}</DataCell>
                        <DataCell>
                            {formatCurrencyElseDefault(cdAccountOverview?.deleteCost)}
                        </DataCell>
                    </Row>
                    <Row>
                        <DataCell colSpan={2}>
                            <Typography variant="small" weight="medium">
                                {isRefund? 'Total Endorsement Refund' : 'Total Endorsement Cost'}
                            </Typography>
                        </DataCell>
                        <DataCell>
                            {formatCurrencyElseDefault(
                                cdAccountOverview?.netCostOfEndorsement
                            )}
                        </DataCell>
                    </Row>
                    <Row>
                        <DataCell colSpan={3}>
                            <StyledEmptySpace />
                        </DataCell>
                    </Row>
                    <Row>
                        <DataCell colSpan={3}>
                            <StyledSpaceBetween style={spaceBetweenStyle}>
                                <Typography variant="small" weight="medium">
                                    Current CD Balance (
                                    {isCDBalanceSufficient ? 'Sufficient' : 'Low'})
                                </Typography>
                                <Typography
                                    variant="small"
                                    color={isCDBalanceSufficient ? 'secondary' : 'error'}
                                >
                                    {formatCurrencyElseDefault(
                                        cdAccountOverview?.cdBalance
                                    )}
                                </Typography>
                            </StyledSpaceBetween>
                        </DataCell>
                    </Row>
                    {!isCDBalanceSufficient && (
                        <Row>
                            <DataCell colSpan={3}>
                                <StyledSpaceBetween style={spaceBetweenStyle}>
                                    <Typography variant="small" weight="medium">
                                        Amount Required
                                    </Typography>
                                    <Typography variant="small" color="secondary">
                                        {formatCurrencyElseDefault(amountRequired)}
                                    </Typography>
                                </StyledSpaceBetween>
                            </DataCell>
                        </Row>
                    )}
                    {isRefund && (
                        <Row>
                            <DataCell colSpan={3}>
                                <StyledSpaceBetween style={spaceBetweenStyle}>
                                    <Typography variant="small" weight="medium">
                                        New CD Balance
                                    </Typography>
                                    <Typography variant="small" color="secondary">
                                        {formatCurrencyElseDefault(amountRequired)}
                                    </Typography>
                                </StyledSpaceBetween>
                            </DataCell>
                        </Row>
                    )}
                </Table>
            </StyledFreshdeskMessageWrapper>
        </WithThemeProvider>);
    };

    return (
        <>
            <Modal
                setIsVisible={closeFreshdeskModal}
                isVisible={visible}
                disablePadding
            >
                <WithThemeProvider>
                    <StyledModalContainer>
                        <StyledModalBody>
                            <StyledModalHeader>
                                <Typography variant="medium" weight="medium">
                            Freshdesk Message
                                </Typography>
                                <Typography variant="small" color="secondary">
                            We’ll attach this message freshdesk ticket number{' '}
                                    <Typography variant="small" weight="medium">
                                        {endorsement?.supportTicketId}
                                    </Typography>
                                </Typography>
                            </StyledModalHeader>
                            <CostingTable edit />
                        </StyledModalBody>
                        <StyledModalCTA>
                            <LoopButton
                                variant="outline"
                                size="large"
                                onClick={closeFreshdeskModal}
                            >
                                Cancel
                            </LoopButton>
                            <LoopButton
                                variant="filled"
                                size="large"
                                onClick={onSendMessage}
                                isLoading={isLoading}
                            >
                                Send Message
                            </LoopButton>
                        </StyledModalCTA>
                    </StyledModalContainer>
                </WithThemeProvider>
            </Modal>

            <Modal isVisible={editModal} setIsVisible={cancelEditModal}>
                <Typography variant="medium" weight="medium" color="primary">
                    Edit Message
                </Typography>

                <div>
                    <StyledTextArea
                        value={updatedFreshdeskMessage}
                        onChange={(message) =>
                            setUpdatedFreshdeskMessage(message.target.value)
                        }
                    />
                </div>

                <StyledHorizontalButtonWrapper>
                    <StyledButtonSpacing>
                        <LoopButton
                            size="medium"
                            variant={'outline'}
                            onClick={cancelEditModal}
                        >
                            Cancel
                        </LoopButton>
                    </StyledButtonSpacing>

                    <StyledButtonAlignment>
                        <LoopButton
                            size="medium"
                            variant={
                                freshdeskMessage !== updatedFreshdeskMessage
                                    ? 'filled'
                                    : 'disabled'
                            }
                            onClick={handleSaveEditMessage}
                        >
                            Save
                        </LoopButton>
                    </StyledButtonAlignment>
                </StyledHorizontalButtonWrapper>
            </Modal>
        </>
    );
};

export default EndorsementFdModal;
