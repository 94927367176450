import { Card, Form, LoopButton, Shadow, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { StyledFormContainer, StyledContainer, StyledEnrolmentReportContainer, StyledFormFieldWidth } from './styles';
import { IEnrolmentReportCard } from './types';
import { isEnrolmentReportEnabled } from '../../../utils/featureFlags';
import moment from 'moment';

const EnrolmentReportCard: React.FunctionComponent<IEnrolmentReportCard> = ({ onSumbit }) => {
    const [fromDate, setFromDate] = React.useState(new Date());
    const [toDate, setToDate] = React.useState(new Date());
    const [toDateError, setToDateError] = React.useState('');
    const onFormSubmit = async () => {
        const fromDateMoment = moment(fromDate).startOf('day');
        const toDateMoment = moment(toDate).startOf('day');
        if (fromDateMoment.isSameOrAfter(toDateMoment)) {
            setToDateError('From Date should be prior to To Date.');
            return;
        }
        if (toDateMoment.diff(fromDateMoment, 'days') > 30) {
            setToDateError('Cannot generate report for more than 30 days.');
            return;
        }
        setToDateError('');
        await onSumbit(fromDateMoment.toDate(), toDateMoment.toDate());
    };
    if (!isEnrolmentReportEnabled) {
        return <></>;
    }
    return (
        <StyledContainer>
            <Shadow variant="small">
                <Card>
                    <StyledEnrolmentReportContainer>
                        <div>
                            <Typography variant="title2">Download Enrolment Report</Typography>
                        </div>
                        <StyledFormContainer>
                            <StyledFormFieldWidth>
                                <Form.FormField label="From:" id="from-date">
                                    <Form.DatePicker maxDate={new Date()} onChange={setFromDate} value={fromDate} />
                                </Form.FormField>
                            </StyledFormFieldWidth>
                            <StyledFormFieldWidth>
                                <Form.FormField label="To:" id="to-date">
                                    <Form.DatePicker
                                        maxDate={new Date()}
                                        error={toDateError}
                                        onChange={setToDate}
                                        value={toDate}
                                    />
                                </Form.FormField>
                            </StyledFormFieldWidth>
                        </StyledFormContainer>
                        <LoopButton variant="filled" onClick={onFormSubmit}>
                            Download
                        </LoopButton>
                    </StyledEnrolmentReportContainer>
                </Card>
            </Shadow>
        </StyledContainer>
    );
};

export default EnrolmentReportCard;
