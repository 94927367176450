import { ICompanyData } from '../../../../../redux/slices/CompanySlice/types';
import { IDropdownItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types';
import { IPolicyPlan } from '../../../../../redux/slices/PolicySlice/types';
import { getPolicyType } from '../../../../../utils/common';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import { IBulkUploadExcelData } from './types';
import { IMemberUpload } from '../../../../../redux/slices/ValidatorSlice/types';

export const prepareCompanyFilter = (companies: ICompanyData[]): IDropdownItem[] => {
    return companies?.map((company: any) => {
        return {
            name: company?.name,
            value: company?.id
        };
    });
};

export const preparePolicyFilter = (policies: IPolicyPlan[]): IDropdownItem[] => {
    const policyList = policies?.map((policy: any) => {
        const policyError = `(${policy.id + ') (Schema Error)'}`;
        const policyType = `(${
            getPolicyType(policy.policyType, policy.isTopUpPolicy, policy.isParentalPolicy) || 'N/A'
        })`;
        const policyId = `(${policy.id || 'N/A'})`;
        const policyNickName = `${policy.nickName ? '(' + policy.nickName + ')' : ''}`;
        const policyName = `${policy.insurerName} ${policyType} ${policyId} ${policyNickName}`;
        return {
            name: policy.error && policy.error.length ? policyError : policyName,
            value: policy?.id
        };
    });
    policyList.push({
        name: 'Not Applicable',
        value: 'Not Applicable'
    });
    return policyList;
};

export const validateExcelDate = (inputDate: string): Date | string => {
    const dateFormatUtils = new DateFormatUtils();
    const isValid = dateFormatUtils.validateExcelDateFormat(inputDate);
    if (isValid) return dateFormatUtils.excelToBackendDateFormat(inputDate);
    return inputDate;
};

export const formatExcelDatesForBulkUpload = (excelMemberData: IBulkUploadExcelData): Record<string, unknown> => {
    return {
        userId: excelMemberData['User ID']?.trim(),
        employee_id: excelMemberData['Employee ID']?.trim(),
        relationship_to_account_holders: excelMemberData['Relationship to Account Holder']?.trim() || '',
        name: excelMemberData['Name']?.trim() || '',
        slabId: excelMemberData['Slab ID'] || '',
        data_addition: '',
        mobile: excelMemberData['Mobile'] || '',
        email_address: excelMemberData['Email Address'] || '',
        company: '',
        gender: excelMemberData['Gender'] || '',
        ctc: excelMemberData['CTC'] || '',
        enrolment_due_date: excelMemberData['Enrolment Due Date (DD/MMM/YYYY)']
            ? validateExcelDate(excelMemberData['Enrolment Due Date (DD/MMM/YYYY)'])
            : '',
        date_of_birth: excelMemberData['Date of Birth (DD/MMM/YYYY)']
            ? validateExcelDate(excelMemberData['Date of Birth (DD/MMM/YYYY)'])
            : '',
        policy_start_date: excelMemberData['Coverage Start Date (DD/MMM/YYYY)']
            ? validateExcelDate(excelMemberData['Coverage Start Date (DD/MMM/YYYY)'])
            : '',
        policy_end_date: '',
        isException: (excelMemberData['Exception'] || '').toLowerCase()
    };
};

export const formatOffboardSheetForUpload = (excelMemberData: Record<string, string>): Record<string, unknown> => {
    const dateFormatUtils = new DateFormatUtils();
    return {
        userId: excelMemberData['User ID'] || '',
        relationship: excelMemberData['Relationship to Account Holder'] || '',
        dateOfLeaving: dateFormatUtils.excelToBackendDateFormat(excelMemberData['Date of Leaving (DD/MMM/YYYY)']),
        policyException: excelMemberData['Policy Exception']
    };
};

export const validateRegistrationData = (registrationData: Record<string, unknown>[]): Error | undefined => {
    if (registrationData?.length < 1) {
        return new Error('No member data found in the sheet');
    }
};

export const generateIdentifyMidTermRequest = (uploadedData: IMemberUpload | null): Record<string, unknown> => {
    const addrequest = uploadedData?.add.map((userData) => {
        return {
            employeeId: userData.employee_id,
            relationship: (userData.relationship_to_account_holders as string).toLowerCase(),
            coverageStartDate: userData.policy_start_date,
            name: userData.name
        };
    });

    const editrequest = uploadedData?.edit.map((userData) => {
        return {
            userId: userData.userId,
            relationship: (userData.relationship_to_account_holders as string).toLowerCase(),
            coverageStartDate: userData.policy_start_date,
            name: userData.name
        };
    });
    const addEditUsers = {
        add: addrequest,
        edit: editrequest
    };
    return addEditUsers;
};

export const generateIdentifyKycRequest = (uploadedData: IMemberUpload | null): Record<string, string[]> => {
    const editUserIds = uploadedData?.edit
        .map((userData) => {
            return userData.userId;
        })
        .filter((userId) => userId) as string[];
    const offBoardUserIds = uploadedData?.offboard
        .map((userData) => {
            return userData.userId;
        })
        .filter((userId) => userId) as string[];

    return {
        editUserIds,
        offBoardUserIds
    };
};
