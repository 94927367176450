import React, { useMemo, useState } from 'react';
import {
    StyledEndorsementStatusWrapper, StyledNextStatus,
    StyledStatus
} from './styles';
import { getStatusLabel } from '../../../../../utils/constants/Endorsement/PossibleValue';
import ChangeEndorsementStatusModal from '../../../../containers/ChangeEndorsementStatusModal';
import { IEndorsementStatusProps } from './types';
import { LoopButton } from '../../../../atoms';
import { IStatus } from '../../../../../redux/slices/EndorsementSlice/types';
import EndorsementFdModal from './EndorsementFdModal';
import { isFreshdeskValidatorIntegrated } from '../../../../../utils/featureFlags';
import { checkIfMidtermNonVerified } from '../../../../../utils/constants/Endorsement';

const EndorsementStateComponent: React.FunctionComponent<IEndorsementStatusProps> = ({
    endorsement,
    currentStatus,
    nextStatus,
    isCDBalanceSufficient,
    cdBalanceOverview,
    toDisableCTAForEndorsementState,
    updateEndorsementStatus,
    policyId
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const isEndoComplete = currentStatus == 'ENDO_COMPLETE';
    const [newState, setNewState] = useState<IStatus>(nextStatus);
    const [freshdeskModal, setFreshdeskModal] = useState(false);

    const openStatusChangeModal = (nextStatus: IStatus) => {
        if (isFreshdeskValidatorIntegrated && nextStatus === 'IN_PROGRESS') {
            setFreshdeskModal(true);
        } else {
            setNewState(nextStatus);
            setIsVisible(true);
        }
    };

    const midTermVerified = useMemo(
        () => !endorsement?.memberData?.filter((member) => checkIfMidtermNonVerified(member, currentStatus)).length,
        [endorsement]
    );

    return (
        <>
            <ChangeEndorsementStatusModal
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                updateEndorsementStatus={updateEndorsementStatus}
                endorsementId={endorsement?.id}
                currentStatus={currentStatus}
                nextStatus={newState}
                policyId={policyId}
            />
            {isFreshdeskValidatorIntegrated &&
                <EndorsementFdModal
                    visible={freshdeskModal}
                    setIsVisible={setFreshdeskModal}
                    endorsement={endorsement}
                    isCDBalanceSufficient={isCDBalanceSufficient}
                    cdAccountOverview={cdBalanceOverview}
                    nextStatus={nextStatus}
                    updateEndorsementStatus={updateEndorsementStatus}
                />
            }
            <StyledEndorsementStatusWrapper>
                <StyledStatus>
                    Current Endorsement Status:
                    <span>{getStatusLabel(currentStatus)}</span>
                </StyledStatus>
                <StyledNextStatus>
                    {!isEndoComplete && (
                        <LoopButton
                            type={
                                (isCDBalanceSufficient ||
                                !toDisableCTAForEndorsementState) &&
                                midTermVerified
                                    ? 'filled'
                                    : 'disabled'
                            }
                            onClick={() => openStatusChangeModal(nextStatus)}
                        >
                            <span>Move to {getStatusLabel(nextStatus)}</span>
                        </LoopButton>
                    )}
                    {currentStatus === 'SENT_TO_INSURER' && (
                        <LoopButton type="outline" onClick={() => openStatusChangeModal('ENDO_DECLINED')}>
                            Move to Endo Declined
                        </LoopButton>
                    )}
                </StyledNextStatus>
            </StyledEndorsementStatusWrapper>
        </>
    );
};
export default EndorsementStateComponent;
