import { IEndorsementSlice } from './types';

export const initialState: IEndorsementSlice = {
    pendingEndorsements: {
        data: null,
        error: null,
        loading: false
    },
    completedEndorsements: {
        data: null,
        error: null,
        loading: false
    }
};
