import React from 'react';
import { IBannerProps } from './types';
import { StyledActionText, StyledBannerTitleComponent, StyledBannerContainer } from './styles';


const Banner: React.FunctionComponent<IBannerProps> = ({
    isActionable,
    action,
    onAction,
    mode,
    children
}) => {
    return (
        <StyledBannerContainer mode={mode}>
            <StyledBannerTitleComponent>{children}</StyledBannerTitleComponent>
            {isActionable ? <StyledActionText onClick={onAction}>{action}</StyledActionText> : null}
        </StyledBannerContainer>
    );
};
export default Banner;
