import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { BusyLady, LogoutIcon } from '../../../../../assets/img';
import {
    StyledContainer,
    StyledTitleContainer,
    StyledSubTitle,
    StyledCardContainer,
    StyledCentralContainer,
    StyledChipContainer,
    StyledMarkAllContainer,
    StyledMarkAllChipContainer
} from './styles';
import { Chip, LoopTable, Typography, Modal, Dialog } from '@loophealth/loop-ui-web-library';
import ChecklistCard from '../../../../containers/ChecklistCard';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { IDocumentReceived, ITableData } from './types';
import { formatTableData, updateRecords } from './utils';
import CustomBottomNavigator from '../../../../containers/CustomBottomNavigator';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import { resetState, setIdentifiedKyc, setRejectedEntries } from '../../../../../redux/slices/ValidatorSlice';

const KycChecklist: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const identifiedKyc = useSelector((state: ReduxState) => state.validatorSlice.identifiedKyc);
    const uploadedData = useSelector((state: ReduxState) => state.validatorSlice.membersToUpload);
    const [tableData, setTableData] = useState<ITableData[]>([]);
    const headers = ['S.No.', 'Employee ID', 'Name', 'Document Received'];
    const [markAll, setMarkAll] = useState<boolean | null>(null);
    const [cancel, setCancel] = useState<boolean>(false);
    const rejectedEntries = useSelector((state: ReduxState) => state.validatorSlice.rejectedEntries);
    const haveEditOffboardEntries = !!(uploadedData?.edit.length || uploadedData?.offboard.length);
    const haveKycEntries = !!(identifiedKyc?.EDIT.length || identifiedKyc?.OFFBOARD.length);

    useEffect(() => {
        if (!haveKycEntries && haveEditOffboardEntries) {
            navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.DNF_CHECKLIST.route);
        }
        if (!haveEditOffboardEntries) {
            navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.VALIDATOR.route);
        }
    }, []);

    // Mark All chip selection manual handling
    useEffect(() => {
        if (tableData.length) {
            if (getCorrectCount === tableData.length) setMarkAll(true);
            if (getIncorrectCount === tableData.length) setMarkAll(false);
            if (
                getCorrectCount + getIncorrectCount === tableData.length &&
                getCorrectCount !== 0 &&
                getIncorrectCount !== 0
            ) {
                setMarkAll(null);
            }
        }
    }, [tableData]);

    useEffect(() => {
        if (haveEditOffboardEntries && haveKycEntries) {
            const formatedData = formatTableData(uploadedData.edit, identifiedKyc);
            setTableData(formatedData);
        }
    }, [identifiedKyc]);

    useEffect(() => {
        if (markAll !== null) {
            const updatedData = [...tableData];
            updatedData.map((itm) => {
                itm['Document Received'].isSelected = markAll;
            });
            setTableData(updatedData);
        }
    }, [markAll]);

    const handleChipClicks = (index: number, value: boolean) => {
        const updatedData = [...tableData];
        updatedData[index]['Document Received'].isSelected = value;
        setTableData(updatedData);
    };

    const getCorrectCount = tableData.filter((record) => record['Document Received'].isSelected).length;
    const getIncorrectCount = tableData.filter((record) => record['Document Received'].isSelected === false).length;
    const activateNext = getCorrectCount + getIncorrectCount ===
        (identifiedKyc?.EDIT.length || 0) + (identifiedKyc?.OFFBOARD.length || 0);

    const navigateToValidator = () => {
        dispatch(resetState());
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.VALIDATOR.route);
    };

    const navigateNext = () => {
        const { updatedIdentifiedKyc, updatedRejectedEntries } = updateRecords(
            tableData,
            identifiedKyc,
            uploadedData,
            rejectedEntries
        );
        dispatch(setIdentifiedKyc(updatedIdentifiedKyc));
        dispatch(setRejectedEntries(updatedRejectedEntries));
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.DNF_CHECKLIST.route);
    };

    return (
        <StyledContainer>
            <StyledCentralContainer>
                <div>
                    <SVG src={BusyLady} />
                </div>
                <StyledTitleContainer>
                    <Typography variant="title3" weight="medium">
                        Before you move forward...
                    </Typography>

                    <StyledSubTitle>
                        <Typography variant="medium" weight="regular">
                            Confirm if you have the KYC documents to edit member details
                        </Typography>
                    </StyledSubTitle>
                </StyledTitleContainer>

                <StyledCardContainer>
                    <ChecklistCard type="primary" title="Correct Edit Members" subtitle={`${getCorrectCount} Lives`} />
                    <ChecklistCard
                        type="error"
                        title="Incorrect Edit Members"
                        subtitle={`${getIncorrectCount} Lives`}
                    />
                </StyledCardContainer>

                <StyledMarkAllContainer>
                    <Typography variant="small" weight="regular">
                        Mark all {tableData.length} of {tableData.length} results
                    </Typography>
                    <StyledMarkAllChipContainer>
                        <Chip
                            key={'Yes'}
                            label={'Mark all as Yes'}
                            isActive={markAll === true}
                            onClick={() => setMarkAll(true)}
                        />
                        <Chip
                            key={'No'}
                            label={'Mark all as No'}
                            isActive={markAll === false}
                            onClick={() => setMarkAll(false)}
                            variant="error"
                        />
                    </StyledMarkAllChipContainer>
                </StyledMarkAllContainer>

                <LoopTable
                    headers={headers}
                    data={tableData}
                    processor={{
                        [headers[3]]: {
                            cell: (value) => (
                                <StyledChipContainer>
                                    <Chip
                                        key={'Yes'}
                                        label={'Yes'}
                                        isActive={(value as IDocumentReceived).isSelected === true}
                                        onClick={() => handleChipClicks((value as Record<string, number>).index, true)}
                                    />
                                    <Chip
                                        key={'No'}
                                        label={'No'}
                                        isActive={(value as IDocumentReceived).isSelected === false}
                                        onClick={() => handleChipClicks((value as Record<string, number>).index, false)}
                                        variant="error"
                                    />
                                </StyledChipContainer>
                            )
                        }
                    }}
                />
            </StyledCentralContainer>
            <CustomBottomNavigator
                leftButtonClick={() => setCancel(true)}
                rightButtonClick={navigateNext}
                activateNext={activateNext}
                rightButtonLabel="Next"
                leftButtonLabel="Cancel"
            />

            <Modal isVisible={cancel} setIsVisible={setCancel}>
                <Dialog
                    variant="vertical"
                    icon={LogoutIcon}
                    description="Are you sure you want to exit? Because on exiting you’ll loose all the progress."
                    title={'You’ll loose all the progress!'}
                    primaryButtonText="Yes, Exit"
                    onPrimaryButtonClicked={() => navigateToValidator()}
                    onSecondaryButtonClicked={() => setCancel(false)}
                    secondaryButtonText="Cancel"
                    buttonVariant="error"
                />
            </Modal>
        </StyledContainer>
    );
};

export default KycChecklist;
