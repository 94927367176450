import React from 'react';
import {
    StyledContainer,
    StyledCompanyName,
    StyledCompanyIdentiyContainer,
    StyledCompanyAllignmentContainer,
    StyledButtonContainer
} from './styles';
import { Card, LabelButton } from '../../atoms';
import { CompanyIdentity } from '../../atoms';
import { ICompanyCard } from './types';
import CompanyDetailsRow from '../../atoms/CompanyDetailsRow';
import { StyledCompanyDetailsAllignment } from '../../atoms/CompanyDetailsRow/styles';

const CompanyCard: React.FunctionComponent<ICompanyCard> = ({ ...companyDetails }) => {
    const companyInfo: Record<string, string>[] = [
        {
            GST: companyDetails.gstNumber || 'N/A'
        },
        {
            PAN: companyDetails.panNumber || 'N/A'
        },
        {
            Address: companyDetails.regdAddress || 'N/A'
        },
        {
            'Company Nickname': companyDetails.nickname || 'N/A'
        },
        {
            'Salesforce Id': companyDetails.salesforceId || 'N/A'
        }
    ];
    const spocInfo: Record<string, string>[] = [
        {
            Name: companyDetails.spocName || 'N/A'
        },
        {
            Email: companyDetails.spocEmail || 'N/A'
        },
        {
            Phone: companyDetails.spocPhoneNumber || 'N/A'
        }
    ];
    return (
        <StyledContainer>
            <Card>
                <StyledCompanyAllignmentContainer>
                    <StyledCompanyIdentiyContainer>
                        <CompanyIdentity
                            companyId={companyDetails.id}
                            companyName={companyDetails.name}
                            companyLogo={companyDetails.companyLogo}
                            companyStatus={companyDetails.active}
                        />
                    </StyledCompanyIdentiyContainer>
                    <StyledButtonContainer>
                        {' '}
                        {companyDetails.handleAddPolicy && (
                            <LabelButton onClick={companyDetails.handleAddPolicy} isInverted>
                                Add New Policy
                            </LabelButton>
                        )}
                    </StyledButtonContainer>
                </StyledCompanyAllignmentContainer>

                <StyledCompanyDetailsAllignment>
                    {companyInfo.map((entry, index) => {
                        return (
                            <CompanyDetailsRow
                                key={index}
                                field={Object.keys(entry)[0]}
                                value={entry[Object.keys(entry)[0]]}
                            />
                        );
                    })}
                </StyledCompanyDetailsAllignment>
                <StyledCompanyDetailsAllignment>
                    <StyledCompanyName>SPOC Details</StyledCompanyName>
                    {spocInfo.map((entry, index) => {
                        return (
                            <CompanyDetailsRow
                                key={index}
                                field={Object.keys(entry)[0]}
                                value={entry[Object.keys(entry)[0]]}
                            />
                        );
                    })}
                </StyledCompanyDetailsAllignment>
            </Card>
        </StyledContainer>
    );
};

export default CompanyCard;
