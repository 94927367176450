import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCompletedEndorsements, fetchPendingEndorsements } from '../../../adapters';
import { IEndorsements } from './types';

const ENDORSEMENT_ACTION_TYPES = {
    PENDING_ENDORSEMENTS: 'PENDING_ENDORSEMENTS',
    COMPLETED_ENDORSEMENT: 'COMPLETED_ENDORSEMENT'
};

export const fetchPendingEndorsementList = createAsyncThunk(
    ENDORSEMENT_ACTION_TYPES.PENDING_ENDORSEMENTS,
    async () => {
        try {
            const response = await fetchPendingEndorsements();
            return response.data as IEndorsements;
        } catch (e) {
            throw e;
        }
    }
);
export const fetchCompletedEndorsementList = createAsyncThunk(
    ENDORSEMENT_ACTION_TYPES.COMPLETED_ENDORSEMENT,
    async () => {
        try {
            const response = await fetchCompletedEndorsements();
            return response.data as IEndorsements;
        } catch (e) {
            throw e;
        }
    }
);
