import { getMidtermDocument } from '../../../adapters';
import {
    IEndorsementOperationType,
    IEndorsementSource,
    IStatus,
    MemberDataEntity
} from '../../../redux/slices/EndorsementSlice/types';
import { downloadAndGetSignedBlob, formatCurrency, parseResponse, roundToTwo } from '../../common';

const ENDORSEMENT_SOURCE_LABEL: Record<IEndorsementSource, string> = {
    'HRD': 'HR Dashboard',
    'ENROLMENT_PORTAL': 'Enrolment',
    'GENOME': 'Genome',
    'HRMS': 'HRMS'
};

export const getEndorsementSourceLabel = (status: IEndorsementSource): string => ENDORSEMENT_SOURCE_LABEL[status];


const ENDORSEMENT_OPERATION_LABEL: Record<IEndorsementOperationType, string> = {
    'ADD': 'Add',
    'EDIT': 'Edit',
    'DELETE': 'Delete'
};

export const getEndorsementOperationTypeLabel = (status: IEndorsementOperationType): string =>
    ENDORSEMENT_OPERATION_LABEL[status];

export const formatCurrencyElseDefault = (currency?: number): string =>
    (currency === undefined || currency === null ? '-' : `₹${formatCurrency(currency)}`);

export const roundToTwoDigitElseDefault = (currency?: number): number => currency ? roundToTwo(currency) : 0;

export const checkIfMidtermNonVerified = (
    member: MemberDataEntity,
    endoStatus: IStatus
): boolean => {
    return Boolean(
        member.source === 'HRD' &&
        member.operationType === 'ADD' &&
        ['DRAFT', 'IN_PROGRESS'].includes(endoStatus) &&
      ((member.relationship?.toLowerCase() === 'spouse' &&
        member.midTermDocumentURL) ||
        member.relationship?.toLowerCase() === 'child') &&
      (!member.isMidTermDocumentVerified)
    );
};

export const checkIfMidtermApplicable = (
    member: MemberDataEntity,
    endoStatus: IStatus
): boolean => {
    return Boolean(
        member.source === 'HRD' &&
        member.operationType === 'ADD' &&
        ['DRAFT', 'IN_PROGRESS'].includes(endoStatus) &&
      ((member.relationship?.toLowerCase() === 'spouse' &&
        member.midTermDocumentURL) ||
        member.relationship?.toLowerCase() === 'child')
    );
};

export const getSignedBlob = async (fileUrl: string | null): Promise<Blob | null> => {
    if (!fileUrl) return null;
    try {
        const [error, response] = await parseResponse(getMidtermDocument(fileUrl));
        if (error) throw error;

        const data = response.data;
        return await downloadAndGetSignedBlob(data.signedURL, data.method);
    } catch (error) {
        return null;
    }
};
