import React, { ChangeEvent } from 'react';
import { Typography } from '@loophealth/loop-ui-web-library';
import {
    StyledEnrolmentPolicyContainer,
    StyledInsurerLogo,
    StyledPolicyDetailsContainer,
    StyledPolicyInfoContainer
} from './styles';
import { StyledColumn } from '../../atoms';
import { ISelectablePolicyComponentProps } from './types';

const SelectablePolicyComponent: React.FC<ISelectablePolicyComponentProps> = ({
    icon,
    policyType,
    insurer,
    policyId,
    isSelected,
    onSelect
}) => {
    const onPolicySelected = (e: ChangeEvent<HTMLInputElement>) => {
        onSelect(policyId, e.target.checked);
    };

    return (
        <StyledEnrolmentPolicyContainer>
            <StyledPolicyInfoContainer>
                <StyledInsurerLogo src={icon}/>
                <StyledPolicyDetailsContainer>
                    <StyledColumn>
                        <Typography variant='medium' weight='medium'>{policyType}</Typography>
                        <Typography variant='extraSmall' color='secondary'>{insurer}</Typography>
                    </StyledColumn>
                    <Typography variant='small' color='secondary'>
                        Policy ref ID: <Typography variant='small' weight='medium'>
                            {policyId}</Typography>
                    </Typography>
                </StyledPolicyDetailsContainer>
            </StyledPolicyInfoContainer>
            <input type={'checkbox'} checked={isSelected} onChange={onPolicySelected}/>
        </StyledEnrolmentPolicyContainer>
    );
};
export default SelectablePolicyComponent;
